/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, InputAdornment, Typography, Box } from '@mui/material';
import EmailIcon from 'assets/png/email.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import { styled } from '@mui/material/styles';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller, useWatch, useFieldArray } from 'react-hook-form';
import { CRMSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import { CRMType } from 'types';
import AddIcon from 'assets/iconcomponents/addicon';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import { watch } from 'fs';
import {
  storecrm,
  storecrm2,
  storeinfo,
  removecrm,
  removecrmid,
} from 'store/reducers/clientcorporatereducer';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import Loader from 'components/loader';

const CRMDETAILS = ({ click }: { click: any }) => {
  const itemsdefault = {
    choosecrm: '',
    title: '',
    firstname: '',
    lastname: '',
    designation: '',
    email: '',
    phoneno: undefined,
    mobileno: undefined,
    countryCodeText: '',
    rowId: '',
  } as const;
  const [items, setitems] = useState([itemsdefault]);
  const [uploadImage, setUploadImage] = useState<any>({});
  const [localcrm, setlocalcrm] = useState<any>([]);
  const [Ld, setLd] = useState(false);
  const [Localidstorage, setLocalidstorage] = useState<any>([]);
  const [Crmobj, setCrmobj] = useState<any>([]);
  const [rollist, setrollist] = useState<any>([]);
  const [crmlist, setcrmlist] = useState<any>([]);
  const [Locclientoldcrm, setLocclientoldcrm] = useState<any>([]);
  const imgref: any = useRef();
  const subtgref: React.MutableRefObject<any> = useRef(null);
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('mode');
  const clientreducer: any = useAppSelector((store: any) => store.clientreducer);
  const Corporatestore: any = useAppSelector((store: any) => store.corporatereducer);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    getValues,
    // trigger,
    // setError,
    watch,
    formState: { errors },
  } = useForm<CRMType>({
    criteriaMode: 'all',
    defaultValues: {
      items: [
        {
          choosecrm: '',
          title: '',
          firstname: '',
          lastname: '',
          designation: '',
          email: '',
          phoneno: undefined,
          mobileno: undefined,
          countryCodeText: '',
          rowId: '',
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CRMSchema),
  });

  const {
    fields,
    append,
    replace: replaceform,
    update,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'items',
  });

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // localStorage.setItem('refreshstate',JSON.stringify({refresh:true,edit:paramValue === 'edit' ? true :false,image:clientreducer && clientreducer.clientdetail && clientreducer.clientdetail.clientLogo && clientreducer.clientdetail.localImage ? true : false }));

      event.preventDefault();
      alert('save your changes before deleting');
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function MountRender(localcrmRes: any) {
    console.log(localcrm, 'localcrm');
    console.log(localcrmRes, 'localcrm');

    // if (!_.isEmpty(localcrm)) {
    if (!_.isEmpty(localcrmRes)) {
      fields.map((ty: any, idy: number) => {
        const localstate: any = localcrmRes.find(
          (rd1: any) => rd1.id === watch(`items.${idy}.choosecrm`),
        );
        if (!_.isEmpty(localstate)) {
          setValue(
            `items.${idy}.title`,
            localstate && localstate.title
              ? String(localstate.title).toLowerCase() === 'mr'
                ? 'mr'
                : 'mrs'
              : '',
          );
          setValue(`items.${idy}.rowId`, localstate && localstate.id ? localstate.id : '');
          setValue(
            `items.${idy}.firstname`,
            localstate && localstate.firstName ? localstate.firstName : '',
          );
          setValue(
            `items.${idy}.lastname`,
            localstate && localstate.lastName ? localstate.lastName : '',
          );
          setValue(
            `items.${idy}.designation`,
            localstate && localstate.designation ? localstate.designation : '',
          );
          setValue(
            `items.${idy}.email`,
            localstate && localstate.login && localstate.login.email ? localstate.login.email : '',
          );
          setValue(
            `items.${idy}.mobileno`,
            localstate && localstate.mobileNumber ? localstate.mobileNumber : undefined,
          );
          setValue(
            `items.${idy}.countryCodeText`,
            localstate && localstate.countryCode ? localstate.countryCode : '',
          );

          setValue(
            `items.${idy}.phoneno`,
            localstate && localstate.phoneNumber && localstate.countryCode
              ? `${localstate.countryCode}${localstate.phoneNumber}`
              : undefined,
          );
        }
      });
    }
  }

  function handleLocalstoragefunction(idy: number) {
    if (!_.isEmpty(localcrm)) {
      const localstate: any = localcrm.find(
        (rd1: any) => rd1.id === watch(`items.${idy}.choosecrm`),
      );
      console.log(localstate, 'localstate', watch(`items.${idy}.choosecrm`));

      if (!_.isEmpty(localstate)) {
        setValue(
          `items.${idy}.title`,
          localstate && localstate.title
            ? String(localstate.title).toLowerCase() === 'mr'
              ? 'mr'
              : 'mrs'
            : '',
        );
        setValue(`items.${idy}.rowId`, localstate && localstate.id ? localstate.id : '');
        setValue(
          `items.${idy}.firstname`,
          localstate && localstate.firstName ? localstate.firstName : '',
        );
        setValue(
          `items.${idy}.lastname`,
          localstate && localstate.lastName ? localstate.lastName : '',
        );
        setValue(
          `items.${idy}.designation`,
          localstate && localstate.designation ? localstate.designation : '',
        );
        setValue(
          `items.${idy}.email`,
          localstate && localstate.login && localstate.login.email ? localstate.login.email : '',
        );
        setValue(
          `items.${idy}.mobileno`,
          localstate && localstate.mobileNumber ? localstate.mobileNumber : undefined,
        );
        setValue(
          `items.${idy}.countryCodeText`,
          localstate && localstate.countryCode ? localstate.countryCode : '',
        );

        setValue(
          `items.${idy}.phoneno`,
          localstate && localstate.phoneNumber && localstate.countryCode
            ? `${localstate.countryCode}${localstate.phoneNumber}`
            : undefined,
        );
        console.log(';expected mounthandle---');
      }
    }
  }
  async function handleEditLocalstoragefunction(idy: number, localcrmRes: any) {
    // const localstate = Corporatestore.crm[idy]&&Corporatestore.crm[idy].user?Corporatestore.crm[idy].user:undefined;
    // const localstate = localcrmRes?localcrmRes:undefined;
    const localstate: any = localcrmRes.find(
      (rd1: any) => rd1.id === watch(`items.${idy}.choosecrm`),
    );
    if (!_.isEmpty(localstate)) {
      console.log(localstate, 'localstateedited');

      setValue(
        `items.${idy}.title`,
        localstate && localstate.title
          ? String(localstate.title).toLowerCase() === 'mr'
            ? 'mr'
            : 'mrs'
          : '',
      );
      setValue(`items.${idy}.rowId`, localstate && localstate.id ? localstate.id : '');
      setValue(
        `items.${idy}.firstname`,
        localstate && localstate.firstName ? localstate.firstName : '',
      );
      setValue(
        `items.${idy}.lastname`,
        localstate && localstate.lastName ? localstate.lastName : '',
      );
      setValue(
        `items.${idy}.designation`,
        localstate && localstate.designation ? localstate.designation : '',
      );
      setValue(
        `items.${idy}.email`,
        localstate && localstate.login && localstate.login.email ? localstate.login.email : '',
      );
      setValue(
        `items.${idy}.mobileno`,
        localstate && localstate.mobileNumber ? localstate.mobileNumber : undefined,
      );
      setValue(
        `items.${idy}.countryCodeText`,
        localstate && localstate.countryCode ? localstate.countryCode : '',
      );

      setValue(
        `items.${idy}.phoneno`,
        localstate && localstate.phoneNumber && localstate.countryCode
          ? `${localstate.countryCode}${localstate.phoneNumber}`
          : undefined,
      );
      console.log(';expected mounthandle---');
    }
  }

  useEffect(() => {
    console.log(';expected mount');

    // ----------------

    setLd(true);
    getAllListData(
      `${SETTING_ENDPOINT.users}/lite?crm=true&status=true&moduleId=2&screenId=4&organizationId=${orgdy}&sortby=name&order=asc`,
      true,
    )
      .then((res: any) => {
        let localcrmRes: any = null;
        if (res && res.data && res.data.length > 0) {
          console.log(res.data, 'res.data');

          localcrmRes = res.data;
          setcrmlist(
            res.data.map((li1: any) =>
              li1 && !_.isEmpty(li1) && li1.id
                ? {
                    value: li1.id,
                    label:
                      li1.firstName && li1.lastName
                        ? String(`${li1.firstName} ${li1.lastName}`)
                        : '',
                  }
                : '',
            ),
          );

          setlocalcrm(res.data);
        }
        setLd(!true);
        console.log('api mount');
        MountRender(localcrmRes);
        // ------------

        const getvalcrmobj = localStorage.getItem('crmlistid');
        const getvalcrmobjjson = getvalcrmobj ? JSON.parse(getvalcrmobj) : '';
        setCrmobj(getvalcrmobjjson);
        const getval34 = localStorage.getItem('crmlist');
        const getval34json = getval34 ? JSON.parse(getval34) : '';
        setLocclientoldcrm(getval34json);
        if (paramValue === 'edit') {
          console.log(';expected mount2222');
          // if (!_.isEmpty(clientreducer) && !_.isEmpty(clientreducer.clientsCrm)) {
          if (!_.isEmpty(Corporatestore) && !_.isEmpty(Corporatestore.crm)) {
            const totsd = fields.length - Corporatestore.crm.length;
            setLocalidstorage(Corporatestore.crm.map((ol10: any) => ol10.id));
            Array(Math.abs(totsd))
              .fill(1)
              .forEach((r3: any) => append(itemsdefault));
          }

          !_.isEmpty(Corporatestore.crm) &&
            !_.isEmpty(Corporatestore.crm) &&
            Corporatestore.crm.map((ty: any, idy: number) => {
              console.log(';expected mount222277777');
              Corporatestore &&
              _.isArray(Corporatestore.crm) &&
              !_.isEmpty(Corporatestore.crm[idy]) &&
              Corporatestore.crm[idy].userId
                ? setValue(`items.${idy}.choosecrm`, Corporatestore.crm[idy].userId)
                : '';
              console.log(';expected mount6679999');
              handleEditLocalstoragefunction(idy, localcrmRes);
              console.log(';expected mount6679999 finish');
              // handleLocalstoragefunction(idy);
              // Corporatestore &&
              // _.isArray(Corporatestore.crm) &&
              // !_.isEmpty(Corporatestore.crm[idy]) &&
              // Corporatestore.crm[idy].id
              //   ? setValue(
              //       `items.${idy}.id`,
              //       Corporatestore.crm[idy].id ? Corporatestore.crm[idy].id : '',
              //     )
              //   : '';

              // clientreducer && _.isArray(clientreducer.clientsCrm) && !_.isEmpty(clientreducer.clientsCrm[0]) && clientreducer.clientsCrm[0].userId ? setValue('id', clientreducer.clientsCrm[0]) && clientreducer.clientsCrm[0].id:'');: '';

              // ------------

              // if (
              //   Corporatestore &&
              //   !_.isEmpty(Corporatestore.crm) &&
              //   Corporatestore.crm.length > 0 &&
              //   !_.isEmpty(Corporatestore.crm[idy]) &&
              //   !_.isEmpty(Corporatestore.crm[idy].user)
              // ) {
              //   // const hy: any = !_.isEmpty(Corporatestore.crm[idy].user) ? clientreducer.clientsCrm[idy].user:{};
              //   const hy: any = !_.isEmpty(Corporatestore.crm[idy].user)
              //     ? Corporatestore.crm[idy].user
              //     : {};
              //   setValue(
              //     `items.${idy}.title`,
              //     hy && hy.title ? (String(hy.title).toLowerCase() === 'mr' ? 'mr' : 'mrs') : '',
              //   );
              //   setValue(`items.${idy}.firstname`, hy && hy.firstName ? hy.firstName : '');
              //   setValue(`items.${idy}.lastname`, hy && hy.lastName ? hy.lastName : '');
              //   setValue(`items.${idy}.designation`, hy && hy.designation ? hy.designation : '');
              //   setValue(`items.${idy}.email`, hy && hy.login && hy.login.email ? hy.login.email : '');

              //   setValue(`items.${idy}.mobileno`, hy && hy.mobileNumber ? hy.mobileNumber : undefined);
              //   setValue(`items.${idy}.countryCodeText`, hy && hy.countryCode ? hy.countryCode : '');
              //   setValue(
              //     `items.${idy}.phoneno`,
              //     hy && hy.phoneNumber && hy.countryCode
              //       ? `${hy.countryCode}${hy.phoneNumber}`
              //       : undefined,
              //   );
              // }
            });
        }
        if (!_.isEmpty(Corporatestore.crm2) && paramValue !== 'edit') {
          // if (!_.isEmpty(Corporatestore.crm2)) {
          const totsd = fields.length - Corporatestore.crm2.length;
          Array(Math.abs(totsd))
            .fill(1)
            .forEach((r3: any) => append(itemsdefault));
          Corporatestore.crm2.map((ty: any, idy1: number) => {
            if (!_.isEmpty(Corporatestore.crm2[idy1])) {
              const stval1 = Corporatestore.crm2[idy1];
              stval1.userId ? setValue(`items.${idy1}.choosecrm`, stval1.userId) : '';
              handleEditLocalstoragefunction(idy1, localcrmRes);
              // ------------

              // setValue(
              //   `items.${idy1}.title`,
              //   stval1 && stval1.title
              //     ? String(stval1.title).toLowerCase() === 'mr'
              //       ? 'mr'
              //       : 'mrs'
              //     : '',
              // );
              // setValue(`items.${idy1}.firstname`, stval1 && stval1.firstname ? stval1.firstname : '');
              // setValue(`items.${idy1}.lastname`, stval1 && stval1.lastname ? stval1.lastname : '');
              // setValue(
              //   `items.${idy1}.designation`,
              //   stval1 && stval1.designation ? stval1.designation : '',
              // );
              // setValue(`items.${idy1}.email`, stval1 && stval1.email ? stval1.email : '');

              // setValue(
              //   `items.${idy1}.mobileno`,
              //   stval1 && stval1.mobileno ? stval1.mobileno : undefined,
              // );
              // setValue(
              //   `items.${idy1}.countryCodeText`,
              //   stval1 && stval1.countryCodeText ? stval1.countryCodeText : '',
              // );
              // setValue(
              //   `items.${idy1}.phoneno`,
              //   (stval1 && stval1.phoneno && stval1.countryCodeText || stval1 && stval1.phoneno && stval1.countryCode)
              //     ? `${stval1.countryCodeText ?? stval1.countryCode}${stval1.phoneno}`
              //     : (stval1 && stval1.phoneNumber && stval1.countryCodeText || stval1 && stval1.phoneNumber && stval1.countryCode) ? `${stval1.countryCodeText??stval1.countryCode}${stval1.phoneNumber}`
              //     : undefined,
              // );
            }
          });
        }
        // -----------------
      })
      .catch((e) => {
        // console.log(e);
        setLd(!true);
      });

    // ----------------
  }, []);
  // }, [paramValue === 'edit']);

  function onSubmitForm(data: any) {
    setLd(true);
    if (data && !_.isEmpty(data.items)) {
      // const localdat1 =

      const loc1 = data.items.map((i1: any) => ({ userId: i1.choosecrm }));
      if (paramValue !== 'edit') {
        !_.isEmpty(loc1) && dispatch(storecrm(loc1));
        !_.isEmpty(loc1) && localStorage.setItem('clientcrm', JSON.stringify(loc1));
      } else {
        const loc2 = data.items.map((i1: any) => {
          const lcproofid = !_.isEmpty(Crmobj)
            ? Crmobj.find((o1: any) => {
                return o1.userId === i1.choosecrm;
              })
            : {};
          if (Locclientoldcrm.includes(i1.choosecrm)) {
            return { userId: i1.choosecrm, id: lcproofid.id };
          } else {
            return { userId: i1.choosecrm };
          }
        });
        !_.isEmpty(loc2) && dispatch(storecrm(loc2));
        !_.isEmpty(loc2) && localStorage.setItem('clientcrm', JSON.stringify(loc2));
      }
      const loc3 = data.items.map((i1: any) => ({ ...i1, userId: i1.choosecrm }));
      !_.isEmpty(loc3) && dispatch(storecrm2(loc3));
      !_.isEmpty(loc1) && click(loc1);
      !_.isEmpty(loc3) && localStorage.setItem('clientcrm2', JSON.stringify(loc3));
      setLd(false);
    }
    // paramValue !== 'edit' ?  dispatch(storecrm([{userId:data.choosecrm}])) : dispatch(storecrm([{userId:data.choosecrm,id:data.id}])) ;
    // dispatch(storecrm2([{...data,userId:data.choosecrm}]));
    // click([{userId:data.choosecrm}]);
  }
  return (
    <Grid item md={8} sm={8} xs={8}>
      {Ld && <Loader />}
      <Typography component={'div'} className='form-box'>
        <Typography
          component={'div'}
          className='client-form-photo-area'
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography component={'p'} className='side-client-text'>
            CRM Details
          </Typography>
          <AddIcon onClick={() => (fields.length === crmlist.length ? '' : append(itemsdefault))} />
        </Typography>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          {fields.map((ers: any, idx: number) => {
            return (
              <React.Fragment key={ers.id}>
                {idx !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      margin: '32px 0px',
                    }}></div>
                ) : (
                  <></>
                )}
                <Grid container columnSpacing={4.25}>
                  {idx !== 0 ? (
                    <div
                      className='list-del-add-icon'
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.rowId`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <span
                              style={{ cursor: 'pointer', marginRight: '24px' }}
                              onClick={() => {
                                const fullvalue: any = watch('items');
                                // setLd(true);

                                value &&
                                  Localidstorage.includes(value) &&
                                  dispatch(removecrmid(value));

                                remove(idx);
                                // subtgref.current.click();
                                // setTimeout(() => {
                                //   //  getValues(`items.${idx}.id`) && dispatch(removecrm(getValues(`items.${idx}.id`)));

                                //   fields
                                //     .filter((e, i) => i !== idx)
                                //     .map((e) => {
                                //       // console.warn(e);
                                //       append(e);
                                //     });
                                //   setLd(false);
                                // });
                              }}>
                              <img src={Deleteimgicon} alt='delete' />
                            </span>
                          );
                        }}
                      />

                      <span>
                        <AddIcon
                          onClick={() => {
                            append(itemsdefault);
                            // inadd();
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Choose CRM <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.choosecrm`}
                        render={({ field: { onChange, value, name } }) => {
                          // console.log(value,'value choose crm');
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={crmlist}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleLocalstoragefunction(idx);
                              }}
                              placeHolder='Select CRM'
                              // isMulti={true}
                              error={errors?.items?.[idx]?.choosecrm?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0 }}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Title</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.title`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              disabled
                              options={[
                                { value: 'mr', label: 'Mr.' },
                                { value: 'mrs', label: 'Mrs.' },
                              ]}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder='Select Title'
                              // isMulti={true}
                              error={errors?.items?.[idx]?.title?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>First Name</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.firstname`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter First Name'
                              error={errors?.items?.[idx]?.firstname?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Last Name</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.lastname`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            // <CustomSelect
                            //   labelKey={'label'}
                            //   valueKey={'value'}
                            //   options={designationData}
                            //   name={name}
                            //   value={value}
                            //   onChange={(e) => {
                            //     onChange(e);
                            //   }}
                            //   placeHolder='Designation'
                            //   error={errors.designation?.message}
                            // />
                            <CustomInput
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Last Name'
                              error={errors?.items?.[idx]?.lastname?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Designation</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.designation`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Designation'
                              error={errors?.items?.[idx]?.designation?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Email</Typography>

                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.email`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img src={EmailIcon} alt='email' />
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Email'
                              error={errors?.items?.[idx]?.email?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>{' '}
                <Grid container columnSpacing={4.25}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Phone No</Typography>
                      <Controller
                        control={control}
                        disabled
                        defaultValue={undefined}
                        name={`items.${idx}.phoneno`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomPhoneInput
                              disabled
                              placeholder=''
                              containerClass={
                                errors?.items?.[idx]?.phoneno?.message ? 'phone-input-error' : ''
                              }
                              inputClass={
                                errors?.items?.[idx]?.phoneno?.message
                                  ? 'phone-input phone-input-no-border'
                                  : 'phone-input'
                              }
                              initialCountry={'in'}
                              specialLabel={''}
                              searchClass={'search-custom-class'}
                              value={value}
                              enableSearch={true}
                              searchNotFound={'No Country Found'}
                              inputStyle={{
                                width: '100%',
                                padding: '9px 11px',
                                borderRadius: '6px',
                                paddingLeft: '60px',
                                color: value && String(value).length > 0 ? '#bdbdbd' : '#bdbdbd',
                                // color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                fontSize: '16px',
                                fontFamily: 'Poppins-Regular',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                // backgroundColor:'#eaecf0'
                              }}
                              error={
                                errors?.items?.[idx]?.phoneno?.message
                                  ? errors?.items?.[idx]?.phoneno?.message
                                  : ''
                              }
                              inputProps={{ tabIndex: 11 }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  {/* <Grid item md={6} sm={12} xs={12} >
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Mobile No</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.mobileno`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            // <CustomInput
                            //   value={value}
                            //   name={name}
                            //   className='custom-input'
                            //   placeHolder='Phone Number'
                            //   error={errors.phoneNumber?.message}
                            //   onChange={(e) => {
                            //     onChange(e);
                            //   }}
                            // />

                            <CustomPhoneInput
                              placeholder=''
                              containerClass={
                                errors?.items?.[idx]?.mobileno?.message ? 'phone-input-error' : ''
                              }
                              inputClass={
                                errors?.items?.[idx]?.mobileno?.message
                                  ? 'phone-input phone-input-no-border'
                                  : 'phone-input'
                              }
                              initialCountry={'in'}
                              specialLabel={''}
                              searchClass={'search-custom-class'}
                              value={value}
                              enableSearch={true}
                              searchNotFound={'No Country Found'}
                              inputStyle={{
                                width: '100%',
                                padding: '9px 11px',
                                borderRadius: '6px',
                                paddingLeft: '60px',
                                color: value && String(value).length > 0 ? '#bdbdbd' : '#bdbdbd',
                                fontSize: '16px',
                                fontFamily: 'Poppins-Regular',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                              }}
                              // onChange={(value, data, event, formattedValue) => {
                              //   const { countryCode, dialCode } = data;
                              //   // setValue(
                              //   //   'homePhoneISO2',
                              //   //   countryCode.toUpperCase(),
                              //   // );
                              //   // console.log(countryCode,'countryCode',value,data);
                              //   onChange(formattedValue);
                              //   //   setValue('phoneNumber', value);
                              //   //   setValue('countryCode', dialCode);
                              //   //   trigger('phoneNumber');
                              // }}
                              error={
                                errors?.items?.[idx]?.mobileno?.message
                                  ? errors?.items?.[idx]?.mobileno?.message
                                  : ''
                              }
                              inputProps={{ tabIndex: 11 }}
                              disabled
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid> */}
                </Grid>
              </React.Fragment>
            );
          })}
        </form>
      </Typography>
    </Grid>
  );
};

export default CRMDETAILS;
