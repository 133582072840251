/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Breadcrumbs, Button, Grid, Link, Modal, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import _, { isEmpty } from 'lodash';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import Loader from 'components/loader';
import CrossIcon from 'assets/iconcomponents/crossicon';
import TickIcon from 'assets/iconcomponents/tickicon';
import CLIENTDETAILS from '../clientdetails';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CRMDETAILS from '../crmdetail';
import { useEffect, useLayoutEffect, useState } from 'react';
import SPOCDETAIL from '../spocdetail';
import ClientSuccessIcon from 'assets/png/clientsuccessicon.svg';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  storeclient,
  storecrm,
  storecrm2,
  storespoc,
  Localstoragestore,
  storelogoid,
  clearcrmspocid,
} from 'store/reducers/clientcorporatereducer';
import Loader from 'components/loader';
import { addObject } from 'store/reducers/clientreducer';
import { tryCatch } from 'ramda';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

//  Main Screen component which as corporate ,crm ,spoc screen as child

const CLIENTADD = () => {
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  // this redux store will give access to fresh data real time on every form save
  const Corporatestore: any = useAppSelector((store: any) => store.corporatereducer);
  const dispatch = useAppDispatch();
  // this store will give the data from edit icon (main table screen ) as objet
  const clientreducer: any = useAppSelector((store: any) => store.clientreducer);
  const [Ld, setLd] = useState(false);
  const [LocalPre, setLocalPre] = useState<any>({});
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('mode');
  const clientidparam = searchParams.get('clientid');
  const [ctgopen, setctgopen] = useState(false);
  const [formfinish, setformfinish] = useState({
    crm: false,
    spoc: false,
    clientdetail: false,
  });
  const [formview, setformview] = useState({
    crm: false,
    spoc: false,
    clientdetail: true,
  });
  const [clientdetailstatic, setclientdetailstatic] = useState<any>({});
  const [crmdetailstatic, setcrmdetailstatic] = useState<object[]>([{}]);
  const [spocdetailstatic, setspocdetailstatic] = useState<object[]>([{}]);

  const { editId } = useParams();
  const navigate = useNavigate();
  const gotoNavigate = (link: any) => {
    navigate(link);
  };

  const localstorageclear = () => {
    localStorage.setItem('clientcrm', JSON.stringify([]));
    localStorage.setItem('clientcrm2', JSON.stringify([]));
    localStorage.setItem('clientdetail', JSON.stringify({}));
    localStorage.setItem('clientspoc', JSON.stringify([]));

    localStorage.setItem('refreshstate', JSON.stringify({}));
    dispatch(storeclient({}));
    dispatch(storecrm([]));
    dispatch(storecrm2([]));
    dispatch(storespoc([]));
    dispatch(clearcrmspocid());
  };

  const handleOpenctg = () => {
    localstorageclear();
    setctgopen(true);
  };
  const handleClosectg = () => {
    gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW);
    localstorageclear();
    setctgopen(false);
  };

  const handleSideBarnavigation = () => {
    // setformview({
    //   crm: false,
    //   spoc: false,
    //   clientdetail: !false,
    // });
    setformfinish({
      crm: !false,
      spoc: !false,
      clientdetail: !false,
    });
  };
  //  functions for left navigation to navigate corporate detail , crm , spoc screen

  function clientdetailsfn(data: any) {
    setformview({ crm: !false, spoc: false, clientdetail: false });
    paramValue !== 'edit'
      ? setformfinish({ crm: false, spoc: false, clientdetail: !false })
      : handleSideBarnavigation();
    setclientdetailstatic(data);
  }
  //  helps to navigate to next spoc screen runs on every sucesform crm form submit
  function crmdetailsfn(data: any) {
    setformview({ crm: false, spoc: !false, clientdetail: false });
    paramValue !== 'edit'
      ? setformfinish({ crm: !false, spoc: false, clientdetail: !false })
      : handleSideBarnavigation();
    setcrmdetailstatic(data);
  }

  function finalformsubmit(dats: any) {
    setspocdetailstatic(dats);
    // console.log(localStorage.getItem('CorporateDetails'), 'localstorage');
    //  setLd(true);
    // setTimeout(() => FinalSubmit(),100);
  }

  const initialApi = async () => {
    try {
      const getapi = await getAllListData(`${ClientsENDPOINT.Client}?id=${clientidparam}`, true);
      if (getapi && !_.isEmpty(getapi) && !_.isEmpty(getapi.data) && !_.isEmpty(getapi.data[0])) {
        dispatch(storeclient(getapi.data[0]));
        dispatch(storelogoid(getapi.data[0].logoId));
        dispatch(storecrm(getapi.data[0].clientsCrm));
        dispatch(storecrm2(getapi.data[0].clientsCrm));
        dispatch(storespoc(getapi.data[0].clientsSpoc));

        localStorage.setItem(
          'crmlist',
          JSON.stringify(
            getapi.data[0].clientsCrm.map((p: any) => (p && p.userId ? p.userId : '')),
          ),
        );
        dispatch(Localstoragestore(getapi.data[0]));
        // setLocalPre(getapi.data[0].clientsSpoc);
      }

      handleSideBarnavigation();
    } catch (e: any) {
      // console.log(e, 'finally');
    } finally {
      // console.log('finally');
    }
  };

  // useEffect(() => {
  //   localStorage.getItem('clientspoc') && JSON.parse(localStorage.getItem('clientspoc')!).length > 0 && localStorage.setItem('clientspoc',JSON.stringify(Corporatestore.spoc));
  // },[Corporatestore.spoc]);
  useLayoutEffect(() => {
    paramValue === 'edit' && handleSideBarnavigation();
    paramValue === 'edit' && localstorageclear();
  }, []);
  useEffect(() => {
    const refreshstate: any = localStorage.getItem('refreshstate');
    const lcsclientdetail = localStorage.getItem('clientdetail');
    const lcscrm = localStorage.getItem('clientcrm');
    const lcscrm2 = localStorage.getItem('clientcrm2');
    const lcsspoc = localStorage.getItem('clientspoc');

    // if (paramValue === 'edit' && refreshstate && (JSON.parse(refreshstate).refresh)) {
    //   handleSideBarnavigation();
    // }
    if (paramValue === 'edit' && refreshstate && !JSON.parse(refreshstate).refresh) {
      initialApi();
    }

    // lcscrm2 && console.log(JSON.parse(lcscrm2),'crmlocal');
    if (refreshstate && JSON.parse(refreshstate).refresh) {
      lcsclientdetail &&
        !_.isEmpty(JSON.parse(lcsclientdetail)) &&
        dispatch(storeclient(JSON.parse(lcsclientdetail)));

      lcscrm &&
        !_.isEmpty(JSON.parse(lcscrm)) &&
        JSON.parse(lcscrm) &&
        dispatch(storecrm(JSON.parse(lcscrm)));

      lcscrm2 &&
        !_.isEmpty(JSON.parse(lcscrm2)) &&
        JSON.parse(lcscrm2) &&
        dispatch(storecrm2(JSON.parse(lcscrm2)));

      lcsspoc &&
        !_.isEmpty(JSON.parse(lcsspoc)) &&
        JSON.parse(lcsspoc) &&
        dispatch(storespoc(JSON.parse(lcsspoc).items));

      dispatch(
        addObject({
          ...(lcsclientdetail && JSON.parse(lcsclientdetail) ? JSON.parse(lcsclientdetail) : {}),
          clientsCrm: lcscrm2 && JSON.parse(lcscrm2),
          clientsSpoc: lcsspoc && JSON.parse(lcsspoc),
        }),
      );
    }
  }, []);

  // useEffect(() => {
  // if(!_.isEmpty(LocalPre)) {
  //   console.log(LocalPre,'LocalPre')
  //   localStorage.setItem('clientcrm2', JSON.stringify(LocalPre.clientsCrm));
  //   localStorage.setItem('clientcrm', JSON.stringify(LocalPre.clientsCrm));
  //   localStorage.setItem('clientdetail',JSON.stringify(LocalPre));
  //   localStorage.setItem('clientspoc',JSON.stringify(LocalPre.clientsSpoc));
  // }
  // },[LocalPre]);

  useEffect(() => {
    // this function will run on refresh
    const handleBeforeUnload = (event: any) => {
      localStorage.setItem(
        'refreshstate',
        JSON.stringify({
          refresh: true,
          edit: paramValue === 'edit' ? true : false,
          image:
            Corporatestore &&
            Corporatestore.client &&
            Corporatestore.client.clientLogo &&
            Corporatestore.client.localImage
              ? true
              : false,
        }),
      );

      // localStorage.getItem('clientspoc') && JSON.parse(localStorage.getItem('clientspoc')!).length > 0 && localStorage.setItem('clientspoc',JSON.stringify(Corporatestore.spoc));
      // localStorage.setItem('clientspoc',JSON.stringify(Corporatestore.spoc));
      //  localStorage.setItem('clientcrm2',JSON.stringify());
      event.preventDefault();

      alert('page refresh');
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const sidbrCustomstyl = {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  };

  return (
    <Grid container>
      {/* {loader && <Loader />} */}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Clients
            </Link>
            {/* <Link
              underline='hover'
              color='inherit'
              href=''
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.USERS.USERSLIST)}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Users
            </Link> */}
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              {paramValue === 'edit' ? 'Edit' : 'Add'} Client
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>
            {paramValue === 'edit' ? 'Edit' : 'Add'} Client
          </Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='cancel-btn mr-16'
              onClick={() => {
                localstorageclear();
                navigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW);
              }}>
              {' '}
              Cancel
            </Button>
            <Button
              className='s-add-btn'
              type='submit'
              form={'user-add'}
              sx={{ textTransform: 'none !important' }}
              // onClick={() => formview.spoc && handleOpenctg()}
            >
              {formview.spoc ? 'Save' : 'Save and Continue'}
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='pad-box'>
          <Grid container columnSpacing={3}>
            <Grid item md={4} sm={4} xs={4}>
              <Typography component={'div'} className='pro-box2'>
                <Typography
                  component={'p'}
                  className='side-client-text'
                  style={{
                    ...sidbrCustomstyl,
                    cursor: 'pointer',
                    color: !formview.crm && !formview.spoc ? '#344051' : '#89888E',
                  }}
                  onClick={() =>
                    setformview(() => ({ crm: false, spoc: false, clientdetail: true }))
                  }>
                  <Typography component={'p'} className='side-client-text2'>
                    Client Details
                  </Typography>
                  {formfinish.clientdetail ? <TickIcon /> : <CrossIcon />}
                </Typography>
                <Typography
                  component={'p'}
                  className='side-client-text'
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.crm ? '#344051' : '#89888E',
                    cursor: formfinish.clientdetail ? 'pointer' : 'inherit',
                  }}
                  onClick={() =>
                    formfinish.clientdetail &&
                    setformview(() => ({ crm: !false, spoc: false, clientdetail: false }))
                  }>
                  <Typography component={'p'} className='side-client-text2'>
                    CRM Details
                  </Typography>
                  {formfinish.crm ? <TickIcon /> : <CrossIcon />}
                </Typography>
                <Typography
                  component={'p'}
                  className='side-client-text'
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.spoc ? '#344051' : '#89888E',
                    cursor: formfinish.clientdetail && formfinish.crm ? 'pointer' : 'inherit',
                  }}
                  onClick={() =>
                    formfinish.clientdetail &&
                    formfinish.crm &&
                    setformview(() => ({ crm: false, spoc: !false, clientdetail: false }))
                  }>
                  <Typography component={'p'} className='side-client-text2'>
                    SPOC Details
                  </Typography>
                  {formfinish.spoc ? <TickIcon /> : <CrossIcon />}
                </Typography>
              </Typography>
            </Grid>
            {/* {!formview.crm && !formview.spoc && <CLIENTDETAILS click={setformview(() => ({ crm: !false, spoc: false }))}/>} */}
            {formview.clientdetail && <CLIENTDETAILS click={clientdetailsfn} />}
            {formview.crm && <CRMDETAILS click={crmdetailsfn} />}
            {formview.spoc && (
              <SPOCDETAIL
                submt={finalformsubmit}
                handleOpenctg={handleOpenctg}
                handleClosectg={handleClosectg}
              />
            )}
          </Grid>
        </Typography>
      </Grid>
      <Modal
        open={ctgopen}
        onClose={handleClosectg}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='logout-modal' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Typography
            className='d-flex-ja mb-24'
            sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <img src={ClientSuccessIcon} alt='client success' />
            <Typography className='client-sucess-con'>
              <Typography className='title' style={{ marginBottom: '9px' }}>
                Client Creation Successful!
              </Typography>
              <Typography className='approval' style={{ marginBottom: '24px' }}>
                Awaiting Approval
              </Typography>
            </Typography>
            <Button className='client-success-modal-btn' onClick={handleClosectg}>
              Ok
            </Button>
          </Typography>
          <CloseIcon
            onClick={handleClosectg}
            style={{
              cursor: 'pointer',
              height: '20px',
              width: '20px',
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}
          />
        </Box>
      </Modal>
      {Ld && <Loader />}
    </Grid>
  );
};

export default CLIENTADD;
