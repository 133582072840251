import { Box, Typography, Modal, Button } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import { Controller, useForm } from 'react-hook-form';
import Closepng from 'assets/png/close.svg';
import { LeaveRemarkType } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { LeaveRemarksSchema } from 'utils/ValidatorSchema';
import { patchData } from 'services/CommonServices';
import { LEAVE_ENDPOINT } from 'utils/Constant';

const LeaveModal = (props: {
  getLeaveData: () => void;
  toggleDrawer: (open: boolean) => void;
  open: boolean;
  modalOpen: (open: boolean) => void;
  title: string;
  rowData: any;
  setRowData: (val: any) => void;
  getLeavePolicyData?: () => void | undefined;
}) => {
  const {
    open,
    modalOpen,
    title,
    rowData,
    setRowData,
    toggleDrawer,
    getLeaveData,
    getLeavePolicyData,
  } = props;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<LeaveRemarkType>({
    criteriaMode: 'all',
    defaultValues: {
      remarks: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeaveRemarksSchema),
  });
  const onSubmitForm = (data: LeaveRemarkType) => {
    const obj = {
      status:
        title === 'Reject'
          ? 'rejected'
          : title === 'Approve'
            ? 'approved'
            : title === 'Cancel'
              ? 'cancelled'
              : '',
      remarks: data.remarks,
    };
    patchData(rowData.id, obj, LEAVE_ENDPOINT.leaves, true)
      .then(() => {
        modalOpen(false);
        setRowData({});
        reset();
        toggleDrawer(false);
        getLeaveData();
        getLeavePolicyData ? getLeavePolicyData() : '';
      })
      .catch(() => {});
  };

  return (
    <Modal open={open} onClose={() => modalOpen(false)}>
      <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
        <Box className='spoc-list-modal center'>
          <img
            src={Closepng}
            alt='close'
            className='closepng'
            onClick={() => {
              modalOpen(false);
              reset();
            }}
          />
          <Typography component={'div'} className='custom-field'>
            <Typography component={'div'} className='reason-for-editing'>
              {title} Leave
            </Typography>
            <Typography component={'p'}>
              Remarks<span>*</span>
            </Typography>
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <CustomInput
                  value={value}
                  name={name}
                  className={'custom-input client-comment-box'}
                  placeHolder='Remarks'
                  isTextArea={true}
                  error={errors.remarks?.message}
                  onChange={onChange}
                />
              )}
              name='remarks'
              control={control}
            />
          </Typography>

          <Typography className='button-con end'>
            <Typography className='buttons editreason-btn'>
              <Button
                onClick={() => {
                  modalOpen(false);
                  reset();
                }}
                className='editreason-btn-close'>
                Cancel
              </Button>
              <Button type='submit' className='editreason-btn-submit'>
                Submit
              </Button>
            </Typography>
          </Typography>
        </Box>
      </form>
    </Modal>
  );
};

export default LeaveModal;
