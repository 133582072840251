/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  InputAdornment,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Modal,
} from '@mui/material';
import EmailIcon from 'assets/png/email.svg';
import Closepng from 'assets/png/close.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { CRMSchema, SPOCSchema } from 'utils/ValidatorSchema';
import _, { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import { CRMType, SPOCType1 } from 'types';
import AddIcon from 'assets/iconcomponents/addicon';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { ClientsENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import {
  changespoc,
  clearcrmspocid,
  removespocid,
  storespoc,
  Adddummyspoc,
  removespocelement,
} from 'store/reducers/clientcorporatereducer';
import Loader from 'components/loader';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { Country, State, City } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const SPOCFORM = ({
  submit,
  onSubmited,
  items,
  setitems,
  itemsdefault,
  handleOpenctg,
  handleClosectg,
  inadd,
  indelete,
}: {
  submit: boolean;
  onSubmited: any;
  items: any;
  setitems: any;
  itemsdefault: any;
  handleOpenctg: any;
  handleClosectg: any;
  inadd: any;
  indelete: any;
}) => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('mode');
  const clientidparam = searchParams.get('clientid');
  const refreshQuery = searchParams.get('refresh');
  const [onmount, setonmount] = useState(true);
  const [Ld, setLd] = useState(false);
  const [Localidstorage, setLocalidstorage] = useState<any>([]);
  const [Currentindex, setCurrentindex] = useState<number>(0);
  const [removespocids, setremovespocids] = useState<any[]>([]);
  const [isRejectionModalOpened, setRejectionModalOpen] = useState(false);
  const [rollist, setrollist] = useState<any>([]);
  const [designationlist, setdesignationlist] = useState<any>([]);
  const [branchlist, setbranchlist] = useState<any>([]);
  const subtgref: React.MutableRefObject<any> = useRef();
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const gotoNavigate = (link: any) => {
    navigate(link);
  };
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const clientreducer: any = useAppSelector((store: any) => store.clientreducer);
  const Corporatestore: any = useAppSelector((store: any) => store.corporatereducer);
  const dispatch = useAppDispatch();
  const lcsspoc = localStorage.getItem('clientspoc');

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    // trigger,
    // setError,
    watch,
    unregister,
    getValues,
    formState: { errors },
  } = useForm<SPOCType1>({
    criteriaMode: 'all',
    defaultValues: {
      items: [
        {
          title: '',
          firstName: '',
          lastName: '',
          designationId: '',
          email: '',
          phoneNumber: undefined,
          mobileno: undefined,
          status: !false,
          branchId: '',
          address1: undefined,
          address2: undefined,
          country: '',
          pincode: '',
          city: '',
          sameAsCorporateAddress: false,
          state: '',
          countryCode: '',
          id: undefined,
          // countryCodeText:'',
        },
      ],
      editReason: 'no reason',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SPOCSchema),
  });

  const {
    fields,
    append,
    replace: replaceform,
    update,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'items',
  });

  const handleRejectClose = () => {
    // reset();

    setValue('editReason', 'undefined');
    setRejectionModalOpen(false);
  };

  // const watchFieldArray:any = watch('items');
  // const controlledFields = fields.map((field, index) => {
  //   return {
  //     ...field,
  //     ...watchFieldArray[index]
  //   };
  // });
  //   useEffect(() => {
  //     if(submit) {
  //         subtgref && subtgref.current && subtgref.current.click();
  //     }
  //   },[submit]);

  const itmedub = fields.map((ty: any, idy: number) =>
    watch(`items.${idy}.sameAsCorporateAddress`),
  );

  function handleSameasCorporateaddress(CurrentLocalindex: number) {
    const geolocal = Corporatestore.client;
    // // console.(geolocal,'geolocal');
    if (geolocal) {
      const parsedlocal = geolocal;
      if (getValues(`items.${CurrentLocalindex}.sameAsCorporateAddress`)) {
        setValue(
          `items.${CurrentLocalindex}.address1`,
          parsedlocal && parsedlocal.address1 ? parsedlocal.address1 : '',
        );
        setValue(
          `items.${CurrentLocalindex}.address2`,
          parsedlocal && parsedlocal.address2 ? parsedlocal.address2 : '',
        );
        setValue(
          `items.${CurrentLocalindex}.country`,
          parsedlocal && parsedlocal.country ? parsedlocal.country : '',
        );
        setValue(
          `items.${CurrentLocalindex}.pincode`,
          parsedlocal && parsedlocal.pincode ? parsedlocal.pincode : '',
        );
        setValue(
          `items.${CurrentLocalindex}.city`,
          parsedlocal && parsedlocal.city ? parsedlocal.city : '',
        );
        setValue(
          `items.${CurrentLocalindex}.state`,
          parsedlocal && parsedlocal.state ? parsedlocal.state : '',
        );
        // setValue('countryCode',parsedlocal && parsedlocal.countryCode ? parsedlocal.countryCode :'');
        return;
      }
      if (!getValues(`items.${CurrentLocalindex}.sameAsCorporateAddress`)) {
        setValue(`items.${CurrentLocalindex}.address1`, '');
        setValue(`items.${CurrentLocalindex}.address2`, '');
        setValue(`items.${CurrentLocalindex}.country`, '');
        setValue(`items.${CurrentLocalindex}.pincode`, '');
        setValue(`items.${CurrentLocalindex}.city`, '');
        setValue(`items.${CurrentLocalindex}.state`, '');
        // setValue('countryCode',parsedlocal && parsedlocal.countryCode ? parsedlocal.countryCode :'');
      }
    }
  }

  useEffect(() => {
    // dispatch(storespoc(watch));
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?sortby=name&order=asc&branchType=client&status=true`,
      true,
    )
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          // // console.(res.data,'branch');
          //   setlocalcrm(res.data);
          setbranchlist(
            res.data.map((li1: any) =>
              li1 && !_.isEmpty(li1) && li1.id
                ? { value: li1.id, label: li1.name ? String(`${li1.name}`) : '' }
                : '',
            ),
          );
        }
      })
      .catch((e) => {
        // console.(e);
      });

    getAllListData(
      `${SETTING_ENDPOINT.designation}?sortby=name&order=asc&designationType=client&status=true`,
      true,
    )
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          // console.(res.data, 'branch');
          //   setlocalcrm(res.data);
          setdesignationlist(
            res.data.map((li1: any) =>
              li1 && !_.isEmpty(li1) && li1.id
                ? { value: li1.id, label: li1.name ? String(`${li1.name}`) : '' }
                : '',
            ),
          );
        }
      })
      .catch((e) => {
        // console.(e);
      });
  }, []);

  useEffect(() => {
    if (paramValue === 'edit') {
      if (Corporatestore && _.isArray(Corporatestore.spoc)) {
        const totsd = fields.length - Corporatestore.spoc.length;
        Array(Math.abs(totsd))
          .fill(1)
          .forEach((r3: any) => append(itemsdefault));
        Corporatestore.spoc.map((eld1: any, ids: number) => {
          if (!_.isEmpty(Corporatestore.spoc)) {
            setLocalidstorage(Corporatestore.spoc.map((ol12: any) => ol12.id));
            const tempval = Corporatestore.spoc[ids];
            console.log(tempval, 'tempval');

            setValue(
              `items.${ids}.branchId`,
              tempval && tempval.branch && tempval.branch.id ? tempval.branch.id : '',
            );
            setValue(`items.${ids}.address1`, tempval && tempval.address1 ? tempval.address1 : '');
            setValue(`items.${ids}.address2`, tempval && tempval.address2 ? tempval.address2 : '');
            setValue(`items.${ids}.country`, tempval && tempval.country ? tempval.country : '');
            setValue(`items.${ids}.pincode`, tempval && tempval.pincode ? tempval.pincode : '');
            setValue(`items.${ids}.city`, tempval && tempval.city ? tempval.city : '');

            setValue(`items.${ids}.state`, tempval && tempval.state ? tempval.state : '');
            setValue(
              `items.${ids}.countryCode`,
              tempval && tempval.countryCode ? tempval.countryCode : '',
            );
            setValue(
              `items.${ids}.sameAsCorporateAddress`,
              tempval && tempval.sameAsCorporateAddress ? tempval.sameAsCorporateAddress : false,
            );
            if (tempval && tempval.sameAsCorporateAddress) {
              handleSameasCorporateaddress(ids);
            }
            setValue(`items.${ids}.status`, tempval && tempval.status ? tempval.status : false);
            setValue(`items.${ids}.id`, tempval && tempval.id ? tempval.id : '');
            setValue(`items.${ids}.title`, tempval && tempval.title ? tempval.title : '');
            setValue(
              `items.${ids}.firstName`,
              tempval && tempval.firstName ? tempval.firstName : '',
            );
            setValue(`items.${ids}.lastName`, tempval && tempval.lastName ? tempval.lastName : '');
            setValue(
              `items.${ids}.designationId`,
              tempval && !_.isEmpty(tempval.designationId) && tempval.designationId
                ? tempval.designationId
                : tempval && !_.isEmpty(tempval.designation) && tempval.designation.id
                  ? tempval.designation.id
                  : '',
            );
            setValue(`items.${ids}.email`, tempval && tempval.email ? tempval.email : '');
            setValue(
              `items.${ids}.phoneNumber`,
              tempval && tempval.countryCode && tempval.phoneNumber
                ? `${tempval.countryCode} ${tempval.phoneNumber}`
                : '',
            );
            // setValue(`mobileno`,tempval && tempval.mobileno?tempval.mobileno:``);
          }
        });
      }
    }
    if (!_.isEmpty(Corporatestore.spoc) && paramValue !== 'edit') {
      const totsd = fields.length - Corporatestore.spoc.length;
      Array(Math.abs(totsd))
        .fill(1)
        .forEach((r3: any) => append(itemsdefault));
      Corporatestore.spoc.map((yt: any, ids: number) => {
        if (!_.isEmpty(Corporatestore.spoc[ids])) {
          const tempval1 = Corporatestore.spoc[ids];

          setValue(
            `items.${ids}.branchId`,
            tempval1 && tempval1.branch && tempval1.branch.id ? tempval1.branch.id : '',
          );
          // setValue(`items.${ids}.branchId`, tempval1 && tempval1.branchId ? tempval1.branchId : '');
          setValue(`items.${ids}.address1`, tempval1 && tempval1.address1 ? tempval1.address1 : '');
          setValue(`items.${ids}.address2`, tempval1 && tempval1.address2 ? tempval1.address2 : '');
          setValue(`items.${ids}.country`, tempval1 && tempval1.country ? tempval1.country : '');
          setValue(`items.${ids}.pincode`, tempval1 && tempval1.pincode ? tempval1.pincode : '');
          setValue(`items.${ids}.city`, tempval1 && tempval1.city ? tempval1.city : '');

          setValue(`items.${ids}.state`, tempval1 && tempval1.state ? tempval1.state : '');
          setValue(
            `items.${ids}.countryCode`,
            tempval1 && tempval1.countryCode ? tempval1.countryCode : '',
          );
          setValue(
            `items.${ids}.sameAsCorporateAddress`,
            tempval1 && tempval1.sameAsCorporateAddress ? tempval1.sameAsCorporateAddress : false,
          );
          if (tempval1 && tempval1.sameAsCorporateAddress) {
            handleSameasCorporateaddress(ids);
          }
          setValue(`items.${ids}.status`, tempval1 && tempval1.status ? tempval1.status : false);
          setValue(`items.${ids}.title`, tempval1 && tempval1.title ? tempval1.title : '');
          setValue(
            `items.${ids}.firstName`,
            tempval1 && tempval1.firstName ? tempval1.firstName : '',
          );
          setValue(`items.${ids}.lastName`, tempval1 && tempval1.lastName ? tempval1.lastName : '');
          setValue(
            `items.${ids}.designationId`,
            tempval1 && !_.isEmpty(tempval1.designationId) && tempval1.designationId
              ? tempval1.designationId
              : '',
          );
          // setValue(
          //   `items.${ids}.designationId`,
          //   tempval1 && tempval1.designationId ? tempval1.designationId : '',
          // );
          setValue(`items.${ids}.email`, tempval1 && tempval1.email ? tempval1.email : '');
          setValue(
            `items.${ids}.phoneNumber`,
            tempval1 && tempval1.countryCode && tempval1.phoneNumber
              ? `${tempval1.countryCode} ${tempval1.phoneNumber}`
              : '',
          );
          // setValue(`mobileno`,tempval1  && tempval1 .mobileno?tempval1 .mobileno:``);
        }
      });
    }
  }, []);

  useEffect(
    () => {
      // items.map((ty:any,idy:number)=> // console.(watch(`items.${idy}.sameAsCorporateAddress`)));
      if (!_.isEmpty(itmedub) && !onmount) {
        const geolocal = Corporatestore.client;
        // // console.(geolocal,'geolocal');
        if (geolocal) {
          const parsedlocal = geolocal;
          fields.map((ty: any, idy: number) => {
            // console.(getValues(`items.${Currentindex}.sameAsCorporateAddress`), 'll');
            if (getValues(`items.${Currentindex}.sameAsCorporateAddress`)) {
              setValue(
                `items.${Currentindex}.address1`,
                parsedlocal && parsedlocal.address1 ? parsedlocal.address1 : '',
              );
              setValue(
                `items.${Currentindex}.address2`,
                parsedlocal && parsedlocal.address2 ? parsedlocal.address2 : '',
              );
              setValue(
                `items.${Currentindex}.country`,
                parsedlocal && parsedlocal.country ? parsedlocal.country : '',
              );
              setValue(
                `items.${Currentindex}.pincode`,
                parsedlocal && parsedlocal.pincode ? parsedlocal.pincode : '',
              );
              setValue(
                `items.${Currentindex}.city`,
                parsedlocal && parsedlocal.city ? parsedlocal.city : '',
              );
              setValue(
                `items.${Currentindex}.state`,
                parsedlocal && parsedlocal.state ? parsedlocal.state : '',
              );
              // setValue('countryCode',parsedlocal && parsedlocal.countryCode ? parsedlocal.countryCode :'');
              return;
            }
            if (!getValues(`items.${Currentindex}.sameAsCorporateAddress`)) {
              setValue(`items.${Currentindex}.address1`, '');
              setValue(`items.${Currentindex}.address2`, '');
              setValue(`items.${Currentindex}.country`, '');
              setValue(`items.${Currentindex}.pincode`, '');
              setValue(`items.${Currentindex}.city`, '');
              setValue(`items.${Currentindex}.state`, '');
              // setValue('countryCode',parsedlocal && parsedlocal.countryCode ? parsedlocal.countryCode :'');
            }
          });
        }
      }

      setonmount(false);
    },
    [
      // ...fields.map((ty: any, idy: number) => watch(`items.${Currentindex}.sameAsCorporateAddress`)),
      // watch('items.0.sameAsCorporateAddress'),
      // watch('items.1.sameAsCorporateAddress'),
      // watch('items.2.sameAsCorporateAddress'),
      // watch('items.3.sameAsCorporateAddress'),
      // watch('items.4.sameAsCorporateAddress'),
      // watch('items.5.sameAsCorporateAddress'),
    ],
  );

  // previous
  // const watchAll:any = watch();

  // useEffect(() => {
  //   // console.(watchAll, 'watching');
  //   dispatch(storespoc([{...watchAll,phoneNumber:watchAll&&watchAll.phoneNumber ? Number((String(watchAll.phoneNumber.match(/\d+/g).join('')).slice(watchAll && watchAll.countryCode ?watchAll.countryCode.length:''))):''}]));
  // },[watchAll]);
  //  previous end
  // useEffect(() => {
  //   dispatch(storespoc(watch()));
  //   localStorage.setItem('clientspoc', JSON.stringify(watch()));
  // },[fields.length]);
  function onSubmitForm(datas: any) {
    // data.preventDefault();
    const datas1 = _.filter(datas, (arr) => _.every(arr, _.keys));
    // console.(datas1, 'datas1');
    const data = _.cloneDeepWith(datas1, (value) => _.omit(value, ['id']));

    // console.(data[0], 'spocdata');
    if (data && !_.isEmpty(data[0])) {
      setLd(true);
      // const datai:any = data.items.map((res1:any,idf:number) => {
      const datai: any = data[0].map((res1: any, idf: number) => {
        // if(!_.isEmpty(res1)) {
        // }
        // console.(res1, 'res1');
        paramValue !== 'edit'
          ? res1 && res1.id && delete res1.id
          : res1 && res1.id && res1.id.length < 1 && delete res1.id;
        return {
          ...res1,
          phoneNumber: Number(
            String(res1?.phoneNumber.match(/\d+/g).join('')).slice(res1?.countryCode.length - 1),
          ),
        };
      });
      datai.forEach((obj: any) => (_.isEmpty(obj['id']) ? delete obj['id'] : ''));
      // console.('spocdata', datai);
      dispatch(storespoc(datai));
      localStorage.setItem('clientspoc', JSON.stringify(datai));

      // final submiting logic

      // setValue('logoId',clientreducer && clientreducer.logoId ? clientreducer.logoId :'');
      const formdata: any = new FormData();
      Corporatestore &&
        Corporatestore.client &&
        Corporatestore.client.logoId &&
        formdata.append('logoId', Corporatestore.client.logoId);
      formdata.append(
        'corporateName',
        Corporatestore && Corporatestore.client && Corporatestore.client.corporateName
          ? Corporatestore.client.corporateName
          : '',
      );
      formdata.append(
        'industryId',
        Corporatestore && Corporatestore.client && Corporatestore.client.industryId
          ? Corporatestore.client.industryId
          : '',
      );
      formdata.append(
        'address1',
        Corporatestore && Corporatestore.client && Corporatestore.client.address1
          ? Corporatestore.client.address1
          : '',
      );
      formdata.append(
        'address2',
        Corporatestore && Corporatestore.client && Corporatestore.client.address2
          ? Corporatestore.client.address2
          : '',
      );
      formdata.append(
        'country',
        Corporatestore && Corporatestore.client && Corporatestore.client.country
          ? Corporatestore.client.country
          : '',
      );
      formdata.append(
        'pincode',
        Corporatestore && Corporatestore.client && Corporatestore.client.pincode
          ? Corporatestore.client.pincode
          : '',
      );
      formdata.append(
        'city',
        Corporatestore && Corporatestore.client && Corporatestore.client.city
          ? Corporatestore.client.city
          : '',
      );
      formdata.append(
        'state',
        Corporatestore && Corporatestore.client && Corporatestore.client.state
          ? Corporatestore.client.state
          : '',
      );
      formdata.append(
        'comments',
        Corporatestore && Corporatestore.client && Corporatestore.client.comments
          ? Corporatestore.client.comments
          : 'test',
      );
      formdata.append('clientCRM', JSON.stringify(Corporatestore.crm));
      formdata.append('clientSPOC', JSON.stringify(datai));
      // formdata.append('clientSPOC', JSON.stringify(Corporatestore.spoc));
      formdata.append(
        'status',
        Corporatestore && Corporatestore.client && Corporatestore.client.status
          ? Corporatestore.client.status
          : !Corporatestore.client.status
            ? Corporatestore.client.status
            : true,
      );
      formdata.append(
        'approvalStatus',
        Corporatestore && Corporatestore.client && Corporatestore.client.status
          ? // ? Corporatestore.client.status
            'inReview'
          : 'inReview',
      );
      formdata.append('editReason', datas.editReason);
      // formdata.append(
      //   'approvalStatus',
      //   Corporatestore && Corporatestore.client && Corporatestore.client.status
      //     ? Corporatestore.client.status
      //     : !Corporatestore.client.status ? Corporatestore.client.status :true,
      // );

      // formdata.append(
      //   'approvalStatus',
      //   Corporatestore && Corporatestore.client && Corporatestore.client.status
      //     ? Corporatestore.client.status
      //     : !Corporatestore.client.status ? Corporatestore.client.status :true,
      // );

      Corporatestore.clientimg.exist &&
        formdata.append(
          'clientLogo',
          Corporatestore.clientimg.exist ? Corporatestore.clientimg.file : '',
        );
      //  // console.(formdata,'formdata');

      if (_.isEmpty(clientidparam) && _.isEmpty(paramValue)) {
        addNewData(
          formdata,
          `${String(ClientsENDPOINT.Client)}`,
          {
            'Content-Type': 'multipart/form-data',
          },
          true,
        )
          .then((res: any) => {
            // console.(res, 'res');
            setLd(!true);
            handleOpenctg();
          })
          .catch((e) => {
            // console.(e);
            setLd(!true);
          });
      }

      if (!_.isEmpty(clientidparam) && paramValue === 'edit') {
        formdata.append(
          'removeClientCrmIds',
          JSON.stringify(
            _.uniq(
              Corporatestore && !_.isEmpty(Corporatestore.removeClientCrmIds)
                ? Corporatestore.removeClientCrmIds
                : [],
            ),
          ),
        );

        Corporatestore &&
          !_.isEmpty(Corporatestore.removeClientSpocIds) &&
          formdata.append(
            'removeClientSpocIds',
            JSON.stringify(_.uniq(Corporatestore.removeClientSpocIds)),
          );
        // formdata.append(
        //     'removeClientSpocIds',
        //     JSON.stringify(
        //       _.uniq(
        //         removespocids
        //           ? removespocids
        //           : Corporatestore && !_.isEmpty(Corporatestore.removeClientSpocIds)
        //             ? Corporatestore.removeClientSpocIds
        //             : [],
        //       ),
        //     ),
        //   );

        updateData(String(clientidparam), formdata, `${String(ClientsENDPOINT.Client)}`, true, {
          'Content-Type': 'multipart/form-data',
        })
          .then((res: any) => {
            // console.(res, 'res');
            setLd(!true);
            gotoNavigate(`${AdminPrivateRoutes.CLIENTS.CLIENTVIEW}`);
            handleRejectClose();
            handleOpenctg();
            dispatch(clearcrmspocid());
          })
          .catch((e) => {
            // console.(e);
            setLd(!true);
          });
      }

      // final submitting logic

      onSubmited(datai);
    }
    //  just for reference old no need
    // // console.(data, 'spocdata',Number(String(data?.phoneNumber.match(/\d+/g).join('')).slice(data?.countryCode.length)));
    // dispatch(storespoc([{...data,phoneNumber:Number((String(data?.phoneNumber.match(/\d+/g).join('')).slice(data?.countryCode.length)))}]));
    // onSubmited([{...data,phoneNumber:Number((String(data?.phoneNumber.match(/\d+/g).join('')).slice(data?.countryCode.length)))}]);
    // no need end
  }

  const ChangeTrigger = (ex: any, ids1: any, index?: number) => {
    // const Spoc = Corporatestore && Corporatestore.spoc ? Corporatestore.spoc : [];
    // const Spoc2 = Spoc.map((er:any,ids:number) => {

    //   if(er.id === getValues(`items.${ids1}.id`) && paramValue === 'edit') {
    //    // console.(true,getValues(`items.${ids1}.id`),er,ex)
    //    return {...er,...ex};
    //   } else {
    //    return paramValue === 'edit' ? er : {...getValues(`items.${ids1}`),...ex};
    //   }

    //  });
    // paramValue !== 'edit'

    // dispatch(changespoc(getValues('items')));
    //  console.log({datum:ex,id:ids1},'hh',watch('items'));
    // dispatch(storespoc(watch()));
    localStorage.setItem('clientspoc', JSON.stringify(watch()));
    //  dispatch(changespoc({...getValues()}));
    // dispatch(changespoc({datum:ex,id:ids1}));

    dispatch(changespoc({ datum: ex, id: ids1, mode: paramValue, index }));
    //  // console.(Spoc2,'Spoc');
    // // console.('id',getValues(`items.${ids1}.id`),'value',`items.${ids1}.designationId`);
  };

  function onSubmitForm2(datas: any) {
    setValue('editReason', '');
    setRejectionModalOpen(true);
    // console.(datas, 'datas');
  }

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  return (
    <>
      <Typography
        component={'div'}
        className='client-form-photo-area'
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component={'p'} className='side-client-text'>
          SPOC Details
        </Typography>
        <AddIcon
          onClick={() => {
            append(itemsdefault);
            dispatch(Adddummyspoc());
          }}
        />
      </Typography>
      <form
        className='private-form'
        id={'user-add'}
        onSubmit={
          paramValue !== 'edit' && !isRejectionModalOpened
            ? handleSubmit(onSubmitForm)
            : handleSubmit(onSubmitForm2)
        }>
        {fields.map((ers: any, idx: number) => {
          // if(_.isEmpty(ers)){
          //   defaultvaluefill(ers);
          //   return <></>;
          // }
          return (
            <React.Fragment key={ers.id}>
              {idx !== 0 ? (
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#EAECF0',
                    margin: '32px 0px',
                  }}></div>
              ) : (
                <></>
              )}
              <Grid container columnSpacing={4.25}>
                {idx !== 0 ? (
                  <div
                    className='list-del-add-icon'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.title`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <span
                            style={{ cursor: 'pointer', marginRight: '24px' }}
                            onClick={() => {
                              setremovespocids((pl) => [...pl, watch(`items.${idx}.id`)]);
                              Localidstorage.includes(watch(`items.${idx}.id`)) &&
                                dispatch(removespocid(watch(`items.${idx}.id`)));
                              dispatch(removespocelement({ indx: idx }));
                              remove(idx);
                              // setTimeout(() => {
                              //   fields
                              //     .filter((e, i) => i !== idx)
                              //     .map((e) => {
                              //       // console.warn(e);
                              //       append(e);
                              //     });
                              // });
                              // console.(value, 'fullvalue');
                            }}>
                            <img src={Deleteimgicon} alt='delete' />
                          </span>
                        );
                      }}
                    />

                    <span>
                      <AddIcon
                        onClick={() => {
                          append(itemsdefault);
                          dispatch(Adddummyspoc());
                          // inadd();
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                <Grid item md={14} sm={12} xs={12}>
                  <Grid item md={5.8} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Title <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.title`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={[
                                { value: 'mr', label: 'Mr.' },
                                { value: 'mrs', label: 'Mrs.' },
                              ]}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                // dispatch(storespoc());

                                ChangeTrigger(
                                  { title: e.target.value },
                                  getValues(`items.${idx}.id`),
                                  idx,
                                );
                                onChange(e);
                              }}
                              placeHolder='Select Title'
                              // isMulti={true}
                              error={errors?.items?.[idx]?.title?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      First Name <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.firstName`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter First Name'
                            error={errors?.items?.[idx]?.firstName?.message}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { firstName: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Last Name <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.lastName`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          // <CustomSelect
                          //   labelKey={'label'}
                          //   valueKey={'value'}
                          //   options={designationData}
                          //   name={name}
                          //   value={value}
                          //   onChange={(e) => {
                          //     onChange(e);
                          //   }}
                          //   placeHolder='Designation'
                          //   error={errors.designation?.message}
                          // />
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Last Name'
                            error={errors?.items?.[idx]?.lastName?.message}
                            onChange={(e) => {
                              // ChangeTrigger({lastName:e.target.value},getValues(`items.${idx}.id`));
                              // {lastName:e.target.value},getValues(`items.${idx}.id`)
                              // dispatch(changespoc({datum:ex,id:ids1}));

                              onChange(e);
                            }}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { lastName: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Designation <span>*</span>
                    </Typography>
                    {/* <Controller
              control={control}
              defaultValue={undefined}
              name='designation'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomInput
                    value={value}
                    name={name}
                    className='custom-input'
                    placeHolder='Enter Designation'
                    error={errors.designation?.message}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                );
              }}
            /> */}
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.designationId`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={
                              designationlist ? designationlist : [{ label: '', value: undefined }]
                            }
                            name={name}
                            value={value}
                            onChange={(e) => {
                              // ChangeTrigger(
                              //   { designation: { id: e.target.value } },
                              //   getValues(`items.${idx}.id`),
                              //   idx,
                              // );
                              ChangeTrigger(
                                { designationId: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                              onChange(e);
                            }}
                            placeHolder='Select Designation'
                            // isMulti={true}
                            error={errors?.items?.[idx]?.designationId?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Email <span>*</span>
                    </Typography>

                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.email`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <img src={EmailIcon} alt='email' />
                                </InputAdornment>
                              ),
                            }}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { email: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Email'
                            error={errors?.items?.[idx]?.email?.message}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={4.25}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Phone No <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.phoneNumber`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          // <CustomInput
                          //   value={value}
                          //   name={name}
                          //   className='custom-input'
                          //   placeHolder='Phone Number'
                          //   error={errors.phoneNumber?.message}
                          //   onChange={(e) => {
                          //     onChange(e);
                          //   }}
                          // />
                          <CustomPhoneInput
                            placeholder=''
                            containerClass={
                              errors?.items?.[idx]?.phoneNumber?.message ? 'phone-input-error' : ''
                            }
                            inputClass={
                              errors?.items?.[idx]?.phoneNumber?.message
                                ? 'phone-input phone-input-no-border'
                                : 'phone-input'
                            }
                            initialCountry={'in'}
                            specialLabel={''}
                            searchClass={'search-custom-class'}
                            value={value}
                            enableSearch={true}
                            searchNotFound={'No Country Found'}
                            inputStyle={{
                              width: '100%',
                              padding: '9px 11px',
                              borderRadius: '6px',
                              paddingLeft: '60px',
                              color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                              fontSize: '16px',
                              fontFamily: 'Poppins-Regular',
                              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            }}
                            onChange={(value, data, event, formattedValue) => {
                              const { countryCode, dialCode } = data;
                              // setValue(
                              //   'homePhoneISO2',
                              //   countryCode.toUpperCase(),
                              // );
                              // // console.(countryCode,'countryCode',value,data);
                              // console.(dialCode, value, 'phn', formattedValue);
                              onChange(value);

                              // setValue('phoneNumber', value);
                              setValue(`items.${idx}.countryCode`, `+${dialCode}`);
                              // setValue('countryCodeText', countryCode);
                              //   trigger('phoneNumber');
                            }}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { phoneNumber: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                              ChangeTrigger(
                                { countryCode: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            error={
                              errors?.items?.[idx]?.phoneNumber?.message
                                ? errors?.items?.[idx]?.phoneNumber?.message
                                : ''
                            }
                            inputProps={{ tabIndex: 11 }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      SPOC’s Branch <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.branchId`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={branchlist ? branchlist : [{ label: '', value: undefined }]}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              ChangeTrigger(
                                { branch: { id: e.target.value } },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                              onChange(e);
                            }}
                            placeHolder='Select Branch'
                            // isMulti={true}
                            error={errors?.items?.[idx]?.branchId?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ display: 'none' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Mobile No</Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.mobileno`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          // <CustomInput
                          //   value={value}
                          //   name={name}
                          //   className='custom-input'
                          //   placeHolder='Phone Number'
                          //   error={errors.phoneNumber?.message}
                          //   onChange={(e) => {
                          //     onChange(e);
                          //   }}
                          // />
                          <CustomPhoneInput
                            placeholder=''
                            containerClass={
                              errors?.items?.[idx]?.mobileno?.message ? 'phone-input-error' : ''
                            }
                            inputClass={
                              errors?.items?.[idx]?.mobileno?.message
                                ? 'phone-input phone-input-no-border'
                                : 'phone-input'
                            }
                            initialCountry={'in'}
                            specialLabel={''}
                            searchClass={'search-custom-class'}
                            value={value}
                            enableSearch={true}
                            searchNotFound={'No Country Found'}
                            inputStyle={{
                              width: '100%',
                              padding: '9px 11px',
                              borderRadius: '6px',
                              paddingLeft: '60px',
                              color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                              fontSize: '16px',
                              fontFamily: 'Poppins-Regular',
                              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                            }}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { countryCode: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            onChange={(value, data, event, formattedValue) => {
                              const { countryCode, dialCode } = data;
                              // setValue(
                              //   'homePhoneISO2',
                              //   countryCode.toUpperCase(),
                              // );
                              // // console.(countryCode,'countryCode',value,data);

                              setValue(`items.${idx}.countryCode`, dialCode);
                              // setValue('countryCodeText', countryCode);
                              onChange(formattedValue);
                              //   setValue('phoneNumber', value);
                              //   trigger('phoneNumber');
                            }}
                            error={
                              errors?.items?.[idx]?.mobileno?.message
                                ? errors?.items?.[idx]?.mobileno?.message
                                : ''
                            }
                            inputProps={{ tabIndex: 11 }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={4.25} className='mt-10'>
                <Grid item md={6} sm={12} xs={12} alignSelf='flex-end'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      SPOC Status <span>*</span>
                    </Typography>
                    {/* <RadioGroup
                      className="custom-radio"
                      row
                      aria-labelledby=""
                      name=""
                      >
                      <FormControlLabel value="Active" control={<Radio />} label="Active" />
                      <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
    
                      </RadioGroup> */}

                    <Controller
                      render={({ field }) => (
                        <>
                          <RadioGroup className='custom-radio' row {...field}>
                            <FormControlLabel value={true} control={<Radio />} label='Active' />
                            <FormControlLabel value={false} control={<Radio />} label='Inactive' />
                          </RadioGroup>
                          {errors?.items?.[idx]?.status && (
                            <span>You must select a SPOC Status</span>
                          )}
                        </>
                      )}
                      name={`items.${idx}.status`}
                      control={control}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={4.25}>
                <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '16px' }}>
                  <Typography component={'div'} className=' d-flex custom-field'>
                    <Controller
                      control={control}
                      defaultValue={false}
                      name={`items.${idx}.sameAsCorporateAddress`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <Checkbox
                            className='custom-checkbox'
                            sx={{
                              '&:hover': { bgcolor: 'transparent' },
                            }}
                            disableRipple
                            color='default'
                            checkedIcon={<CheckedIcon />}
                            icon={<CheckIcon />}
                            checked={value}
                            name={name}
                            value={value}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { sameAsCorporateAddress: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            onChange={(e) => {
                              setCurrentindex(idx);
                              onChange(e);

                              handleSameasCorporateaddress(idx);
                            }}
                          />
                        );
                      }}
                    />
                    <Typography
                      // sx={{ display: 'none' }}
                      component={'p'}
                      style={{ marginLeft: '10px', marginTop: '5px' }}>
                      Is the SPOC’s address going to be same as Corporate Address?{' '}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
              {/* <Typography component={'div'} className='client-form-photo-area'>
                <Typography component={'p'} className='side-client-text' sx={{ display: 'none' }}>
                  Corporate Details
                </Typography>
              </Typography> */}
              <Grid container columnSpacing={4.25}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Address 1 <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.address1`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Address'
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { address1: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            error={errors?.items?.[idx]?.address1?.message}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Address 2</Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.address2`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          // <CustomSelect
                          //   labelKey={'label'}
                          //   valueKey={'value'}
                          //   options={designationData}
                          //   name={name}
                          //   value={value}
                          //   onChange={(e) => {
                          //     onChange(e);
                          //   }}
                          //   placeHolder='Designation'
                          //   error={errors.designation?.message}
                          // />
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Address'
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { address2: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            error={errors?.items?.[idx]?.address2?.message}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Country <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.country`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          // <CustomSelect
                          //   labelKey={'label'}
                          //   valueKey={'value'}
                          //   options={rollist ? rollist : [{ label: '', value: undefined }]}
                          //   name={name}
                          //   value={value}
                          //   onChange={(e) => {
                          //     onChange(e);
                          //   }}
                          //   placeHolder='Select Country'
                          //   // isMulti={true}
                          //   error={errors.country?.message}
                          // />
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={country}
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Country'
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { country: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            error={errors?.items?.[idx]?.country?.message}
                            onChange={(e) => {
                              onChange(e);
                              setValue(`items.${idx}.state`, '');
                              setValue(`items.${idx}.city`, '');
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      State <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.state`}
                      render={({ field: { onChange, value, name } }) => {
                        const options: any = [];
                        if (watch(`items.${idx}.country`)) {
                          State.getStatesOfCountry(watch(`items.${idx}.country`)).map(
                            (state: any) => {
                              options.push({ label: state.name, value: state.isoCode });
                            },
                          );
                        }
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={options}
                            value={value ? value : null}
                            name={name}
                            className='custom-input'
                            placeHolder='State'
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { state: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            error={errors?.items?.[idx]?.state?.message}
                            onChange={(e) => {
                              onChange(e);
                              setValue(`items.${idx}.city`, '');
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      City <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.city`}
                      render={({ field: { onChange, value, name } }) => {
                        const options: any = [];
                        if (watch(`items.${idx}.state`)) {
                          City.getCitiesOfState(
                            watch(`items.${idx}.country`),
                            watch(`items.${idx}.state`),
                          ).map((city: any) => {
                            options.push({ label: city.name, value: city.name });
                          });
                        }
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={options}
                            value={value ? value : null}
                            name={name}
                            className='custom-input'
                            placeHolder='City'
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { city: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                            error={errors?.items?.[idx]?.city?.message}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Pincode <span>*</span>
                    </Typography>

                    <Controller
                      control={control}
                      defaultValue={undefined}
                      name={`items.${idx}.pincode`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            className='custom-input'
                            onChange={onChange}
                            value={value}
                            name={name}
                            placeHolder='Enter Pincode'
                            type={'number'}
                            error={errors?.items?.[idx]?.pincode?.message}
                            onBlur={(e) => {
                              // // console.('blur');

                              ChangeTrigger(
                                { pincode: e.target.value },
                                getValues(`items.${idx}.id`),
                                idx,
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              {Ld && <Loader />}
            </React.Fragment>
          );
        })}

        <Modal open={isRejectionModalOpened} onClose={handleRejectClose}>
          <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
            <Box
              className='spoc-list-modal center'
              // style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}
            >
              <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose} />
              <Typography component={'div'} className='custom-field'>
                <Typography component={'div'} className='reason-for-editing'>
                  Reason for Editing
                </Typography>
                <Typography component={'p'}>
                  Comments <span>*</span>
                </Typography>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      isTextArea={true}
                      className={`custom-input client-comment-box ${
                        errors.editReason?.message ? 'client-comment-box-error' : ''
                      }`}
                      placeHolder='Type your comments'
                      error={errors.editReason?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      // onBlur={(e) => {
                      //   // // console.('blur');

                      //   ChangeTrigger({editReason:e.target.value},getValues(`items.${idx}.id`));
                      // }}
                    />
                  )}
                  name='editReason'
                  control={control}
                />
              </Typography>
              {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
              <div className='button-con end'>
                <div className='buttons editreason-btn'>
                  <button onClick={handleRejectClose} className='editreason-btn-close'>
                    Cancel
                  </button>
                  <button
                    type='submit'
                    // form={'user-add'}
                    className='editreason-btn-submit'>
                    Submit
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        {/* this is for dum,m,y to trigger form */}
        {/* <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  ref={subtgref}
                  sx={{ display: 'none' }}>
                  Submit
                </Button> */}
      </form>
    </>
  );
};

export default SPOCFORM;
