import { Grid, Typography, Breadcrumbs, Link, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import EditIcon from 'assets/png/edit.svg';
import { getSingleData } from 'services/CommonServices';
import { BillingENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import ApprovalModal from '../ApprovalModal';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import { Country, State } from 'country-state-city';

const BillingView = () => {
  const location = useLocation();
  const [searchParams]: any = useSearchParams();
  const [clientData, setClientData] = useState<any>([]);
  const [candidateData, setCandidateData] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [addClientData, setAddClientData] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState<{ open: boolean; title: string }>({
    open: false,
    title: '',
  });

  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  useEffect(() => {
    if (location?.state?.id !== searchParams.get('id')) {
      gotoNavigate(AdminPrivateRoutes.DASHBOARD);
    } else {
      getSingleData(searchParams.get('id'), BillingENDPOINT.billing)
        .then((res) => {
          if (
            res &&
            !_.isEmpty(res.data) &&
            res.data &&
            res.data.length > 0 &&
            !_.isEmpty(res.data[0])
          ) {
            const data = res?.data[0];

            if (!_.isEmpty(data)) {
              searchParams.set('positionId', data.resume.positionId);
              searchParams.set('resumeId', data.resume.id);
              setClientData((pl: any) => [
                ...pl,
                {
                  title: 'Client Name',
                  subtitle: data?.resume?.client?.corporateName
                    ? data?.resume?.client?.corporateName
                    : '-',
                },
                {
                  title: 'Position',
                  subtitle: data?.resume?.position?.title ? data?.resume?.position?.title : '-',
                },
                {
                  title: 'Recuriter',
                  subtitle: data?.resume?.createdBy?.firstName
                    ? `${data?.resume?.createdBy?.firstName} ${
                        data?.resume?.createdBy?.lastName ? data?.resume?.createdBy?.lastName : ''
                      }`
                    : '-',
                },
                {
                  title: 'Account Holder',
                  subtitle: data?.resume?.position?.crm[0]?.crm?.user?.firstName
                    ? `${data?.resume?.position?.crm[0]?.crm?.user?.firstName} ${
                        data?.resume?.position?.crm[0]?.crm?.user?.lastName
                          ? data?.resume?.position?.crm[0]?.crm?.user?.lastName
                          : ''
                      }`
                    : '-',
                },
                {
                  title: 'Billing %',
                  subtitle: data?.billingPercentage
                    ? `${Number(data?.billingPercentage).toFixed(0)}%`
                    : '-',
                },
                {
                  title: 'Billing Amount',
                  subtitle: data?.billingAmount
                    ? `${Number(data?.billingAmount).toLocaleString('en-IN')}`
                    : '-',
                },
                {
                  title: 'Proof of Offer',
                  subtitle: data?.personal?.noticePeriod ? data?.personal?.noticePeriod : '---',
                },
              ]);
              setCandidateData((pl: any) => [
                ...pl,
                {
                  title: 'Candidate Name',
                  subtitle: data?.resume?.personal?.firstName
                    ? `${data?.resume?.personal?.firstName} ${
                        data?.resume?.personal?.lastName ? data?.resume?.personal?.lastName : ''
                      }`
                    : '-',
                },
                {
                  title: 'CTC Offered',
                  subtitle: data?.resume?.offeredCTC
                    ? Number(data?.resume?.offeredCTC).toFixed(0)
                    : '-',
                },
                {
                  title: 'Designation Offered',
                  subtitle: data?.resume?.designationOffered
                    ? data?.resume?.designationOffered
                    : '-',
                },
                {
                  title: 'Billing Date',
                  subtitle: data?.billingDate
                    ? moment(data?.billingDate).format('DD-MM-YYYY')
                    : '-',
                },
                {
                  title: 'Joined Date',
                  subtitle: data?.resume?.joinedDate
                    ? moment(data?.resume?.joinedDate).format('DD-MM-YYYY')
                    : '-',
                },
              ]);
              setInvoiceData(() => [
                {
                  title: 'Invoice No',
                  subtitle: '---',
                },
                {
                  title: 'Invoice Date',
                  subtitle: '---',
                },
                {
                  title: 'Professional Fees',
                  subtitle: '---',
                },
                {
                  title: 'Tax',
                  subtitle: '---',
                },
              ]);
              setPaymentData(() => [
                {
                  title: 'Mode of Payment',
                  subtitle: '---',
                },
                {
                  title: 'Payment Received Date',
                  subtitle: '---',
                },
                {
                  title: 'Payment Received ',
                  subtitle: '---',
                },
                {
                  title: 'Reference No',
                  subtitle: '---',
                },
                {
                  title: 'Shortfall',
                  subtitle: '---',
                },
                {
                  title: 'Payment Remarks',
                  subtitle: '---',
                },
              ]);
              const billingCountry = Country.getAllCountries().filter(
                (country) => country.isoCode === data?.billingCountry,
              );
              const billingState = State.getStatesOfCountry(data?.billingCountry).filter(
                (state: any) => state.isoCode === data?.billingState,
              );
              const courierCountry = Country.getAllCountries().filter(
                (country) => country.isoCode === data?.courierCountry,
              );
              const courierState = State.getStatesOfCountry(data?.courierCountry).filter(
                (state: any) => state.isoCode === data?.courierState,
              );
              setAddClientData(() => [
                {
                  title: 'Address To',
                  subtitle: data.name ? data.name : '---',
                },
                {
                  title: 'Designation',
                  subtitle: data.designation ? data.designation : '---',
                },
                {
                  title: 'Billing Address',
                  subtitle:
                    data.billingAddress1 || data.billingAddress2
                      ? `${data.billingAddress1} 
                    ${data.billingAddress2}, ${billingCountry[0]?.name},
                    ${billingState[0]?.name},   
                    ${data.billingCity}-${data.billingPincode}.`
                      : '---',
                },
                {
                  title: 'Courier Address',
                  subtitle:
                    data.courierAddress1 || data.courierAddress2
                      ? `${data.courierAddress1} 
                    ${data.courierAddress2}, ${courierCountry[0]?.name},
                    ${courierState[0]?.name},   
                    ${data.courierCity}-${data.courierPincode}.`
                      : '---',
                },
                {
                  title: 'GSTN',
                  subtitle: data.gstn ? data.gstn : '---',
                },
                {
                  title: 'Remarks',
                  subtitle: data.remarks ? data.remarks : '---',
                },
                {
                  title: 'Phone Number',
                  subtitle: data.phoneNumber ? `${data.countryCode} ${data.phoneNumber}` : '---',
                },
                {
                  title: 'Email',
                  subtitle: data.email ? data?.email : '---',
                },
              ]);
            }
          }
        })
        .catch((err) => {
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalOpen = (title: string) => {
    setModalOpen({ open: true, title });
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.BILLING.BILLINGLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Billing
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Billing
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Billing</Typography>
          <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {location?.state?.isApproval ? (
              <>
                <span style={{ marginTop: '5px', marginRight: '15px' }}>
                  <img
                    src={EditIcon}
                    alt='Edit'
                    className='pointer'
                    onClick={() => {
                      gotoNavigate(
                        `${AdminPrivateRoutes.BILLING.BILLINGEDIT}?id=${searchParams.get(
                          'id',
                        )}&positionId=${searchParams.get('positionId')}&resumeId=${searchParams.get(
                          'resumeId',
                        )}`,
                      );
                    }}
                  />
                </span>
                <Typography
                  component={'div'}
                  sx={{
                    width: '96px',
                    height: '36px',
                    borderRadius: '6px',
                    padding: '12px 10px 10px 10px',
                    border: '1px solid #D0D5DD',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#344051',
                    cursor: 'pointer',
                  }}
                  onClick={() => gotoNavigate(AdminPrivateRoutes.BILLING.BILLINGAPPROVE)}>
                  Cancel
                </Typography>
                <div style={{ width: '16px' }}></div>
                <Typography
                  component={'div'}
                  sx={{
                    width: '96px',
                    height: '36px',
                    borderRadius: '6px',
                    padding: '12px 10px 10px 10px',
                    border: '1px solid #EE4B22',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#EE4B22',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleModalOpen('Reject Billing')}>
                  Reject
                </Typography>
                <div style={{ width: '16px' }}></div>
                <Typography
                  component={'div'}
                  className='client-add-btn'
                  onClick={() => handleModalOpen('Approve Billing')}>
                  <Typography>Approve</Typography>
                </Typography>
              </>
            ) : (
              <Typography component={'div'} className='d-flex-a'>
                <Button className='s-add-btn'> Download</Button>
              </Typography>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='main-content view-position'
            style={{ padding: '20px', minHeight: '100%' }}>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              style={{ minHeight: '100%' }}>
              <div className='section-heading'>Client Information </div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {clientData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <div className='section-heading'>Candidate Information </div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {candidateData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <div className='section-heading'>Invoice Details </div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {invoiceData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <div className='section-heading'>Payment Details </div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {paymentData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <div className='section-heading'>Additional Client Information</div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {addClientData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className={dat1.title === 'Email' ? 'email-heading' : 'sub-heading'}>
                        {dat1.subtitle}
                      </div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      <ApprovalModal
        open={modalOpen.open}
        title={modalOpen.title}
        setOpen={setModalOpen}
        id={location?.state?.id}
      />
    </Grid>
  );
};
export default BillingView;
