// import { DesignationType } from 'types';
import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import 'yup-phone-lite';

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().trim().required('Password is required'),
});
export const otp = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
});
export const generateOtp = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

export const changepasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm Password is required')
    .when('newPassword', {
      is: (newPassword: any) => (newPassword && newPassword.length > 0 ? true : false),
      then: () => Yup.string().oneOf([Yup.ref('newPassword')], "Passwords don't match"),
    }),
  newPassword: Yup.string()
    .trim()
    .required('New Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password must contain one uppercase, one lowercase, one number and one special case character',
    ),
});
export const createpasswordSchema = Yup.object().shape({
  email: Yup.string().optional().email('Email is invalid'),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm Password is required')
    .when('password', {
      is: (password: any) => (password && password.length > 0 ? true : false),
      then: () => Yup.string().oneOf([Yup.ref('password')], "Passwords don't match"),
    }),
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password must contain one uppercase, one lowercase, one number and one special case character',
    ),
});
export const ForgotpasswordSchema = Yup.object().shape({
  email: Yup.string().trim().required('Email is required').email('Email is invalid'),
});

export const functionalArea = Yup.object().shape({
  organizationId: Yup.string().required('City is required'),
  name: Yup.string()
    .required('Functional Area is required')
    .trim()
    .test('noWhitespace', 'This field cannot contain only spaces.', (value) =>
      value && value.length > 0 ? true : false,
    ),
  description: Yup.string()
    .required('Description is required')
    .max(300, '300 is the maximum character length')
    .trim()
    .test('noWhitespace', 'This field cannot contain only spaces.', (value) =>
      value && value.length > 0 ? true : false,
    ),
  status: Yup.boolean().required('State is required'),
});

// myplan screen validation
export const MyplanSchema = Yup.object({
  type: Yup.string().trim().required('Type is required'),
  userId: Yup.string().trim().required('userId is required'),
  status: Yup.boolean().default(true),
  planTasks: Yup.array().of(
    Yup.lazy((value: any) => {
      const { workPlanType } = value; // Get the value of checked field
      return workPlanType === 'others'
        ? Yup.object().shape({
            session: Yup.string().trim().required('Session is required'),
            workPlanType: Yup.string().trim().required('Work Plan Type is required'),
            date: Yup.string().trim().required('Task Date is required'),
            reason: Yup.string().trim().required('Reason is required'),
            comments: Yup.string().trim().required('Comments is required'),
            approvalStatus: Yup.string().default('inReview'),
            id: Yup.string().optional(),
          })
        : Yup.object().shape({
            session: Yup.string().trim().required('Session is required'),
            workPlanType: Yup.string().trim().required('Work Plan Type is required'),
            date: Yup.string().trim().required('Task Date is required'),
            clientId: Yup.string().trim().required('Client Name is required'),
            positionId: Yup.string().trim().required('Position is required'),
            approvalStatus: Yup.string().default('inReview'),
            id: Yup.string().optional(),
          });
    }),
  ),
});
// export const MyplanSchema = Yup.object({
//   type:  Yup.string().trim().required('Type is required'),
//   items: Yup.array().of(
//     Yup.object().shape({
//       session: Yup.string().trim().required('Session is required'),
//       workPlanType: Yup.string().trim().required('Work Plan Type is required'),
//       date:  Yup.string().trim().required('Task Date is required'),
//       clientId:  Yup.string().trim().required('Client Name is required'),
//       positionId:  Yup.string().trim().required('Position is required'),
//       reason:  Yup.string().trim().required('Reason is required'),
//       comments:  Yup.string().trim().required('Comments is required'),
//     }),
//   ),
// });

export const PositionSchema2 = Yup.object().shape({
  Openingsnumber: Yup.number()
    .positive('No. of openings is required')
    .required('No. of openings is required')
    .typeError('No. of openings is required'), // Enforce presence
  // Minimum value
});
export const PositionSchema3 = Yup.object().shape({
  reason: Yup.string().trim().required('Reason is required'), // Enforce presence
  // Minimum value
});
export const ChangeCRMSchema = Yup.object().shape({
  crmTo: Yup.string().trim().optional(),
  crmFrom: Yup.string().trim().optional(),
  newCRM: Yup.string().trim().required('New CRM is required'),
  recruiter: Yup.string().trim().optional(),
  remarks: Yup.string().trim().optional(),
});
export const AddBillingSchema = Yup.object().shape({
  canditateName: Yup.string().trim().required('Canditate Name is required'),
  position: Yup.string().trim().required('Position is required'),
  title: Yup.string().trim().required('Title is required'),
  clientName: Yup.string().trim().required('Client Name is required'),
  joinedDate: Yup.string().trim().required('Joined Date is required'),
  ctcOffered: Yup.number()
    .transform((v) => (Number.isNaN(v) ? null : v))
    .positive('CTC Offered must be a positive number')
    .typeError('CTC Offered should be number')
    .required('CTC Offered is required'),
  designationOffered: Yup.string().trim().required('Designation Offered is required'),
  billingPercentage: Yup.number()
    .transform((v) => (Number.isNaN(v) ? null : v))
    .positive('Billing % must be a positive number')
    .typeError('Billing % should be number')
    .required('Billing % is required'),
  billingAmount: Yup.string().trim().required('Billing Amount is required'),
  billingDate: Yup.string().trim().required('Billing Date is required'),
  additionalClientName: Yup.string().trim().required('Name is required'),
  additionalClientDesignation: Yup.string().trim().required('Designation is required'),
  additionalClientAddress1: Yup.string().trim().required('Billing Address 1 is required'),
  additionalClientAddress2: Yup.string().trim().required('Billing Address2 is required'),
  additionalClientState: Yup.string().trim().required('State is required'),
  additionalClientCity: Yup.string().trim().required('City is required'),
  additionalClientPincode: Yup.string().trim().required('Pincode is required'),
  additionalClientCountry: Yup.string().trim().required('Country is required'),
  courierCountry: Yup.string().trim().required('Country is required'),
  sameBillingAddress: Yup.boolean().optional(),
  sameSpocAddress: Yup.boolean().optional(),
  courierAddress1: Yup.string().trim().required('Courier Address 1 is required'),
  courierAddress2: Yup.string().trim().required('Courier Address2 is required'),
  courierState: Yup.string().trim().required('State is required'),
  courierCity: Yup.string().trim().required('City is required'),
  courierPincode: Yup.string().trim().required('Pincode is required'),
  gstn: Yup.string()
    .trim()
    .test('is-15-characters', 'GSTN must be 15 characters', function (value: any) {
      if (value === '') {
        return true; // If the value is null or empty, it passes validation
      }
      return value.length === 15; // If the value is provided, it must be exactly 15 characters
    }),
  purchaseOrder: Yup.string().trim().optional(),
  remarks: Yup.string().trim().optional(),
  email: Yup.string().trim().required('Email is required').email('Email is invalid'),
  countryCodeText: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  countryCode: Yup.string().optional(),
  phoneNumberFormatted: Yup.string()
    .phone('GB', 'Please enter a valid phone number')
    .required('Phone Number is required'),
});
export const ViewPositionSchema = Yup.object().shape({
  status: Yup.string().trim().required('New Status is required'), // Enforce presence
  // Minimum value
});
export const PositionSchema = Yup.object().shape({
  organizationId: Yup.string().required('organizationId is required'),
  clientId: Yup.string().required('Client Name is required'),
  spocId: Yup.string().required('SPOC Name is required'),
  title: Yup.string().trim().required('Job Title is required'),
  location: Yup.string().trim().required('Job Location is required'),
  country: Yup.string().trim().required('Job Country is required'),
  jobCode: Yup.string().required('Job Code is required'), // prefix should be updated in organization
  functionalAreaId: Yup.string().required('Functional Area is required'),
  industry: Yup.string().trim().required('Industry is required'),
  gender: Yup.string().required('Gender is required'),
  minExp: Yup.number().required('Minimum Experience is required'),
  maxExp: Yup.number().required('Maxmum Experience is required'),
  minAge: Yup.number()
    .required('Min Age is required') // Enforce presence
    .min(18, 'Must be at least 18')
    .typeError('Min Age is required') // Minimum value
    .max(60, 'Must be at most 60')
    .test('notEqual', 'Min Age must not be equal', function (value) {
      return value !== this.resolve(Yup.ref('maxAge'));
    }), // Maximum value
  maxAge: Yup.number()
    .required('Max Age is required') // Enforce presence
    .min(18, 'Must be at least 18') // Minimum value
    .max(60, 'Must be at most 60')
    .typeError('Max Age is required')
    .test('notEqual', 'Max Age must not be equal to Min Age', function (value) {
      return value !== this.resolve(Yup.ref('minAge'));
    }), // Maximum value,
  qualification: Yup.string().trim().required('Qualification is required'),
  technicalSkillsData: Yup.array()
    .of(Yup.string())
    .required('Technical Skills is required')
    .min(1, 'Technical Skills is required'),
  behaviouralSkillsData: Yup.array()
    .of(Yup.string())
    .required('Behavioural Skills is required')
    .min(1, 'Behavioural Skills is required'),
  minCurrency: Yup.string().trim().required('minCurrency is required'),
  maxCurrency: Yup.string().trim().required('maxCurrency is required'),
  minCtc: Yup.number()
    .required('Min. CTC is required') // Enforce presence
    .integer('Must be an non negative integer')
    .typeError('Min. CTC is required')
    .test('positive', 'Min. CTC Should Be a Positive Integer', (value) => {
      // Additional custom validation for edge cases
      return value > 0; // Example: must be even number
    }),
  maxCtc: Yup.number()
    .required('Max CTC is required') // Enforce presence
    .integer('Must be an non negative integer')
    .typeError('Max CTC is required')
    .test('notEqual', 'Max CTC must not be equal', function (value) {
      const field2Value: any = this.resolve(Yup.ref('minCtc'));
      return value < field2Value || value !== field2Value;
    })
    .test('positive', 'Max CTC Should Be a Positive Integer', (value) => {
      // Additional custom validation for edge cases
      return value > 0; // Example: must be even number
    }),
  expectedJoiningDate: Yup.string().trim().required('Expected Joining Date is required'),
  totalOpenins: Yup.number()
    .required('Total Openings is required')
    .min(1, 'Total Openings Must Be Greater Than Zero'),
  crmId: Yup.string().trim().required('CRM is required'),
  resumeType: Yup.string().trim().required('Resume Type is required'),
  projectType: Yup.string().trim().required('Project Type is required'),
  billableValue: Yup.number()
    .required('Billable value  is required')
    .typeError('Billable value  is required')
    .min(1, 'Billable value Must Be Greater Than Zero'),
  hideResumeContacts: Yup.boolean().required('Hide Resume Contacts is required'),
  publishInWebsite: Yup.boolean().required('Publish in Website is required'),
  jobDescription: Yup.string()
    .trim()
    .min(100, 'Job Description must be at least 100 characters')
    .required('Job Description is required'),
  approvalStatus: Yup.string().trim().required('Approval Status is required'), // approved , rejected
  keyResponsibilities: Yup.string().trim().required('Key Responsibilities is required'), // approved , rejected
  industryExposure: Yup.string().trim().required('Industry Exposure is required'), // approved , rejected
  competencies: Yup.string().trim().required('Competencies is required'), // approved , rejected
  status: Yup.boolean().required('Status is required'), // Active / Inactive
  // recruitersData:Yup.array(Yup.string()).required('recruitersData is required'),
  recruitersData: Yup.array()
    .min(1, 'Recruiters Data is required')
    .of(
      Yup.object().shape({
        userId: Yup.string().required('userId is required'),
        target: Yup.number().required('target is required'),
      }),
    )
    .required('Recruiters Data is required'),
});
export const DesignationYup = Yup.object().shape({
  organizationId: Yup.string().required('OrganizationId is required'),
  name: Yup.string()
    .required('Designation Name is required')
    .trim()
    .test('noWhitespace', 'This field cannot contain only spaces.', (value) =>
      value && value.length > 0 ? true : false,
    ),
  // type: Yup.mixed().nullable().required('Type is required'),
  type: Yup.string().required('Designation Type is required'),
  status: Yup.boolean().required('State is required'),
});
export const branchFormSchema = Yup.object().shape({
  branchName: Yup.string().trim().required('Branch Name is required'),
  branchType: Yup.string().nullable().required('Branch Type is required'),
  address: Yup.string().trim().optional(),
  pincode: Yup.string().trim().optional(),
  status: Yup.string().optional(),
});
export const holidaysAddSchema = Yup.object().shape({
  branchName: Yup.string().nullable().required('Branch Name is required'),
  eventDate: Yup.string().nullable().required('Event Date is required'),
  eventName: Yup.string().trim().nullable().required('Event Name is required'),
  status: Yup.string().nullable().required('Status is required'),
  description: Yup.string().trim().optional(),
});

export const EventsAddSchema = Yup.object().shape({
  eventTitle: Yup.string()
    .nullable()
    .required('Event Title is required')
    .max(35, '35 is the maximum character length'),
  location: Yup.string().nullable().required('Location is required'),
  eventStartDate: Yup.string().trim().nullable().required('Start Date is required'),
  eventEndDate: Yup.string().nullable().required('End Date is required'),
  description: Yup.string().trim().optional().max(300, '300 is the maximum character length'),
  notification: Yup.boolean().optional(),
  eventColor: Yup.string().trim().optional(),
});
export const LeavePolicyAddSchema = Yup.object().shape({
  policyName: Yup.string().nullable().required('Leave policy type is required'),
  noOfDays: Yup.string().nullable().required('Number of days is required'),
  period: Yup.string().trim().nullable().required('Leave period is required'),
  employee: Yup.string().nullable().optional(),
  carryForward: Yup.string().nullable().required('Carry forward is required'),
  earnedLeave: Yup.string().nullable().required('Earned leave is required'),
  status: Yup.string().nullable().required('Policy status is required'),
  description: Yup.string().nullable().optional(),
});
export const industryAddSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Industry Type is required')
    .max(50, '50 is the maximum character length'),
  description: Yup.string().trim().optional().max(300, '300 is the maximum character length'),
  status: Yup.boolean().required('Status is required'),
});
export const AddcategorySchema = Yup.object().shape({
  name: Yup.string().trim().required('Category name is required'),
});
export const EmailAddSchema = Yup.object().shape({
  emailCategoryId: Yup.string().trim().required('Category is required'),
  template: Yup.string().trim().required('Template Name is required'),
  subject: Yup.string().trim().required('Subject is required'),
  content: Yup.string().trim().required('Content is required'),
  status: Yup.boolean().optional(),
  id: Yup.string().trim().optional(),
});
export const rolesFormSchema = Yup.object().shape({
  userFunctionalroleId: Yup.string().nullable().required('User functional role is required'),
  roleId: Yup.string().nullable().required('Role is required'),
  description: Yup.string().trim().required('Description is required'),
  status: Yup.string().optional(),
});
export const qualificationFormSchema = Yup.object().shape({
  qualification: Yup.string().nullable().required('Qualification is required'),
  // degree: Yup.number().nullable().required('Degree is required'),
  degree: Yup.string().nullable().required('Degree is required'),

  // specialization: Yup.string().nullable().required('Specialization is required'),
  // specializationData: Yup.array().of(
  //   Yup.object().shape({
  //     specialization: Yup.string()
  //       .trim()
  //       .test('noWhitespace', 'Specialization is required', (value) =>
  //         value && value.length > 0 ? true : false,
  //       )
  //       .required('Specialization is required'),
  //     itemId: Yup.string().optional(),
  //   }),
  // ),
  specializationData: Yup.array().when('qualification', (qualification: any, schema) => {
    if ([5, 6, 7].includes(Number(qualification))) {
      return schema.of(
        Yup.object().shape({
          specialization: Yup.string().optional(),
        }),
      );
    } else {
      return schema.of(
        Yup.object().shape({
          specialization: Yup.string()
            .trim()
            .test('noWhitespace', 'Specialization is required', (value) =>
              value && value.length > 0 ? true : false,
            )
            .required('Specialization is required'),
          itemId: Yup.string().optional(),
        }),
      );
    }
  }),
  status: Yup.string().optional(),
});
export const DegreeAddschgema = Yup.object().shape({
  qualificationId: Yup.string()
    .required('Qualification Details Id is required')
    .trim()
    .test('noWhitespace', 'This field cannot contain only spaces.', (value) =>
      value && value.length > 0 ? true : false,
    ),
  code: Yup.string().optional(),
  degree: Yup.string()
    .required('Name is required')
    .trim()
    .test('noWhitespace', 'This field cannot contain only spaces.', (value) =>
      value && value.length > 0 ? true : false,
    ),
});
export const profileEditFormSchema = Yup.object().shape({
  title: Yup.string().trim().required('Title is required'),
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  address: Yup.string().trim().required('Address is required'),
  state: Yup.string().trim().required('State is required'),
  city: Yup.string().trim().required('City is required'),
  countryCodeText: Yup.string().optional(),
  saturdayleave: Yup.string().trim().required('Saturday Leave is required'),
  isSunday: Yup.boolean().required('Sunday is required'),
  pincode: Yup.string()
    .trim()
    .required('Pincode is required')
    .min(Number(3), 'Invalid Pincode')
    .max(Number(6), 'Invalid Pincode'),
  country: Yup.string().trim().required('Country is required'),
  email: Yup.string().trim().required('Email is required'),
  phone: Yup.string().optional(),
  formattedValuePhone: Yup.string()
    .phone('GB', 'Please enter a valid phone number')
    .required('Phone Number is required'),
  status: Yup.boolean().required('Status is required'),
  branchId: Yup.string().optional(),
  empCode: Yup.string().optional(),
});

export const userFormSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().nullable().required('Last Name is required'),
  email: Yup.string().trim().required('Email is required').email('Email is invalid'),
  countryCodeText: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  phoneNumberFormatted: Yup.string()
    .phone('GB', 'Please enter a valid phone number')
    .required('Phone Number is required'),
  // .min(Number(12), 'Invalid Phone Number'),
  role: Yup.string().required('Role is required'),
  designation: Yup.string().required('Designation is required'),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  country: Yup.string().trim().required('Country is required'),
  saturdayleave: Yup.string().trim().required('Saturday Leave is required'),
  isSunday: Yup.boolean().required('Sunday is required'),
  // reportingToL1: Yup.string()
  //   .nullable()
  //   .when('role', (role: any) => {
  //     if (![15, 14, 13, 10, 6].includes(Number(role[0]))) {
  //       return Yup.string().required('L1 Reporting is required');
  //     } else {
  //       return Yup.string().nullable().optional();
  //     }
  //   }),
  reportingToL1: Yup.string().nullable().optional(),
  reportingToL2: Yup.string().nullable().optional(),
  // .when('role', (role: any) => {
  //   if (![15, 14, 13, 12, 10, 9, 6, 5].includes(Number(role[0]))) {
  //     return Yup.string().required('L2 Reporting is required');
  //   } else {
  //     return Yup.string().nullable().optional();
  //   }
  // }),
  // signature: Yup.string().required('Signature is required'),
  pincode: Yup.string()
    .trim()
    .required('Pincode is required')
    .min(Number(3), 'Invalid Pincode')
    .max(Number(6), 'Invalid Pincode'),
  signature: Yup.string().optional(),
  crn: Yup.string().optional(),
  branchId: Yup.string().required('Branch is required'),
  empCode: Yup.string().required('Employee ID is required'),
});
export const ClientDetailSchema = Yup.object().shape({
  companylogo: Yup.boolean().optional(),
  industryId: Yup.string().required('Industry is required'),
  status: Yup.boolean().optional(),
  logoId: Yup.string().optional(),
  // companylogo:Yup.boolean().required('Company Logo is required'),
  corporateName: Yup.string().trim().required('Name is required'),
  address1: Yup.string().trim().required('Address 1 is required'),
  address2: Yup.string().trim().optional(),
  country: Yup.string().trim().required('Country is required'),
  pincode: Yup.string()
    .trim()
    .required('Pincode is required')
    .min(Number(3), 'Invalid Pincode')
    .max(Number(6), 'Invalid Pincode'),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  // check: Yup.boolean().optional().required('Status is required'),
  comments: Yup.string().optional(),
});
export const ClientRejectSchema = Yup.object().shape({
  comments: Yup.string().trim().required('Comments is required'),
});
export const PlanRejectSchema = Yup.object().shape({
  comments: Yup.string().trim().required('Remarks is required'),
});
export const ClientFilterSchema = Yup.object().shape({
  from: Yup.string().optional(),
  to: Yup.string().optional(),
  approvalStatus: Yup.string().optional(),
  status: Yup.string().optional(),
  industry: Yup.string().optional(),
  crm: Yup.string().optional(),
});
export const MyplanFilterSchema = Yup.object().shape({
  fromDate: Yup.string().optional(),
  toDate: Yup.string().optional(),
  session: Yup.string().optional(),
  workType: Yup.string().optional(),
  clientId: Yup.string().optional(),
  workPlan: Yup.string().optional(),
  employee: Yup.string().optional(),
});
export const ResumeFilterSchema = Yup.object().shape(
  {
    fromDate: Yup.string().optional(),
    toDate: Yup.string().optional(),
    interviewFrom: Yup.string().optional(),
    interviewTo: Yup.string().optional(),
    branch: Yup.string().optional(),
    employee: Yup.string().optional(),
    minExperience: Yup.number()
      .min(0)
      .max(50)
      .optional()
      // .min(0, 'Min experience cannot be negative')
      .when('maxExperience', {
        is: (maxExp: number) => maxExp !== undefined && maxExp !== null,
        then: () =>
          Yup.number().lessThan(
            Yup.ref('maxExperience'),
            'Min experience should be less than max experience',
          ),
      }),
    maxExperience: Yup.number()
      .optional()
      .min(0)
      .max(50)
      // .min(0, 'Max experience cannot be negative')
      .when('minExperience', {
        is: (minExp: number) => minExp !== undefined && minExp !== null,
        then: () =>
          Yup.number().moreThan(
            Yup.ref('minExperience'),
            'Max experience should be greater than min experience',
          ),
      }),
    currentStatus: Yup.string().optional(),
  },
  [['minExperience', 'maxExperience']],
);
export const InterviewFilterSchema = Yup.object().shape({
  fromDate: Yup.string().optional(),
  toDate: Yup.string().optional(),
  employee: Yup.string().optional(),
  currentStatus: Yup.string().optional(),
});
export const billingFilterSchema = Yup.object().shape({
  fromDate: Yup.string().optional(),
  toDate: Yup.string().optional(),
  createdBy: Yup.string().optional(),
});
export const MailBoxFilterSchema = Yup.object().shape({
  fromDate: Yup.string().optional(),
  toDate: Yup.string().optional(),
  type: Yup.string().optional(),
});
export const LeaveFilterSchema = Yup.object().shape({
  fromDate: Yup.string().optional(),
  toDate: Yup.string().optional(),
  leaveType: Yup.string().optional(),
});
export const resumeUploadSchema = Yup.object().shape({
  positionId: Yup.string().nullable().required('Position For is required'),
  clientId: Yup.string().nullable().required('Client is required'),
  id: Yup.string().optional(),
});
export const resumeConsultantSchema = Yup.object().shape({
  consultantAssessment: Yup.string()
    .nullable()
    .trim()
    .max(250, 'The Maximum words should be 250 characters')
    .required('Reasons for Shortlisting is required'),
  achievements: Yup.string()
    .nullable()
    .trim()
    .max(250, 'The Maximum words should be 250 characters')
    .required('Noteworthy Contributions / Achievements is required'),
  interviewAvailability: Yup.string()
    .nullable()
    .trim()
    .required('Interview availability is required'),
  comments: Yup.string()
    .nullable()
    .trim()
    .max(250, 'The Maximum words should be 250 characters')
    .optional(),
  technicalSkills: Yup.array().nullable().optional(),
  behaviouralSkills: Yup.array().nullable().optional(),
  id: Yup.string().optional(),
  isSaved: Yup.boolean().optional(),
});
export const sendEmailSchema = Yup.object().shape({
  subject: Yup.string().nullable().trim().required('Subject is required'),
  // message: Yup.string().nullable().required('Message is required'),
  message: Yup.string().nullable().trim().optional(),
  candidate: Yup.string().nullable().trim().optional(),
  cc: Yup.array().optional(),
  client: Yup.string().nullable().trim().optional(),
});
export const designationAddSchema = Yup.object().shape({
  designation: Yup.string().nullable().trim().required('New Designation is required'),
});
export const dgreeAddSchema = Yup.object().shape({
  degree: Yup.string().nullable().trim().required('New Degree is required'),
});
export const qualificationAddSchema = Yup.object().shape({
  qualification: Yup.string().nullable().trim().required('New Qualification is required'),
});
export const resumePersonalSchema = Yup.object().shape(
  {
    positionFor: Yup.string().trim().required('Canditate position is required'),
    canditateFirstName: Yup.string().trim().required('Canditate first name is required'),
    canditateLastName: Yup.string().trim().required('Canditate last name is required'),
    countryCodeText: Yup.string().nullable().optional(),
    countryCode: Yup.string().nullable().optional(),
    canditateEmail: Yup.string().trim().required('Email is required').email('Email is invalid'),
    canditatePhoneNo: Yup.string().optional(),
    formatteValue: Yup.string()
      .phone('GB', 'Please enter a valid phone number')
      .required('Phone Number is required'),
    canditateDob: Yup.string().nullable().required('Date of birth is required'),
    canditateGender: Yup.string().nullable().required('Gender is required'),
    passportAndVisa: Yup.boolean().nullable().required('Passport and VISA is required'),
    canditateMaritalStatus: Yup.string().nullable().required('Marital status is required'),
    canditateCurrentDesignation: Yup.string()
      .trim()
      .nullable()
      .required('Current Designation is required'),
    canditateTotalExperienceYear: Yup.string().nullable().required('Total Experience is required'),
    canditateTotalExperienceMonth: Yup.string()
      .nullable()
      .when('canditateTotalExperienceYear', (year: any) => {
        if (year != 0) {
          return Yup.string().required('Month is required').max(Number(2), 'Invalid Month');
        } else {
          return Yup.string().nullable().optional();
        }
      }),
    isSaved: Yup.boolean().nullable().optional(),
    canditateAnnualCtc: Yup.string()
      .nullable()
      .when('canditateTotalExperienceYear', (year: any) => {
        if (year != 0) {
          return Yup.string().trim().required('Annual CTC is required');
        } else {
          return Yup.string().nullable().optional();
        }
      }),
    id: Yup.string().optional(),
    canditateExpectation: Yup.string()
      .nullable()
      .when('canditateTotalExperienceYear', (year: any) => {
        if (year != 0) {
          return Yup.string().trim().required('Expected CTC is required');
        } else {
          return Yup.string().nullable().optional();
        }
      }),
    canditateNoticePeriod: Yup.string()
      .nullable()
      .when(['canditateTotalExperienceYear', 'isWorking'], ([year, value]: any) => {
        if (Number(year) !== 0 && value === 'false') {
          return Yup.string().required('Notice period is required');
        } else {
          return Yup.string().nullable().optional();
        }
      }),
    isWorking: Yup.boolean()
      .nullable()
      .when(['canditateTotalExperienceYear', 'canditateNoticePeriod'], ([year, value]: any) => {
        if (Number(year) !== 0 && value != '') {
          return Yup.string().required('');
        } else {
          return Yup.string().nullable().optional();
        }
      }),
    canditateFamily: Yup.string().trim().nullable().required('Canditate family is required'),
    passportNumber: Yup.string()
      .trim()
      .nullable()
      .when('passportAndVisa', (value: any) => {
        if (value == 'false') {
          return Yup.string().optional();
        } else {
          return Yup.string().nullable().required('Passport No is required');
        }
      }),
    canditatePresentLocation: Yup.string()
      .trim()
      .nullable()
      .max(100, 'The Maximum words should be 100 characters')
      .required('Present location is required'),
    canditateNative: Yup.string().trim().nullable().optional(),
  },
  [['isWorking', 'canditateNoticePeriod']],
);
export const resumeEducationSchema = Yup.object().shape({
  educationData: Yup.array().of(
    Yup.object({
      specializationId: Yup.string()
        .nullable()
        .when('qualificationId', (value: any) => {
          if (![5, 6, 7].includes(Number(value))) {
            return Yup.string().required('Specialization is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      qualificationId: Yup.string().trim().required('Qualification is required'),
      marks: Yup.string().nullable().trim().optional(),
      isSaved: Yup.boolean().optional(),
      degreeId: Yup.string()
        .nullable()
        .when('qualificationId', (value: any) => {
          if (![5, 6, 7].includes(Number(value))) {
            return Yup.string().required('Degree is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      modeOfEducation: Yup.string().nullable().trim().optional(),
      year: Yup.string().trim().required('Year of Passing is required'),
      univercity: Yup.string().nullable().trim().optional(),
      // college: Yup.string().nullable().trim().optional(),
      college: Yup.string()
        .nullable()
        .when('qualificationId', (value: any) => {
          if (![5, 6, 7].includes(Number(value))) {
            return Yup.string().required('College is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      school: Yup.string()
        .nullable()
        .when('qualificationId', (value: any) => {
          if ([5, 6, 7].includes(Number(value))) {
            return Yup.string().required('School is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      // id: Yup.string().optional(),
    }),
  ),
});
export const resumeExperienceSchema = Yup.object().shape({
  experienceData: Yup.array().of(
    Yup.object({
      companyName: Yup.string().nullable().trim().required('CompanyName is required'),
      designation: Yup.string().nullable().trim().required('Designation is required'),
      fromDate: Yup.string().nullable().required('From date is required'),
      toDate: Yup.string()
        .nullable()
        .when('tillDate', (value: any) => {
          if (value == 'false') {
            return Yup.string().required('To date is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      location: Yup.string().nullable().trim().required('Location is required'),
      reportingTo: Yup.string().nullable().trim().required('Reporting To is required'),
      specialization: Yup.string()
        .nullable()
        .trim()
        .max(40, 'The Maximum words should be 40 characters')
        .required('Specialization is required'),
      projectAndCertifications: Yup.string()
        .trim()
        .max(40, 'The Maximum words should be 40 characters')
        .nullable()
        .optional(),
      isSaved: Yup.boolean().optional(),
      vitalInformation: Yup.string().trim().nullable().optional(),
      tillDate: Yup.boolean().optional(),
      id: Yup.string().optional(),
    }),
  ),
});

// export const CRMSchema = Yup.object().shape({
//   choosecrm: Yup.string().trim().required('Choose CRM is required'),
//   id: Yup.string().optional(),
//   title: Yup.string().optional(),
//   firstname: Yup.string().optional(),
//   lastname: Yup.string().optional(),
//   designation: Yup.string().optional(),
//   email: Yup.string().optional(),
//   countryCodeText: Yup.string().optional(),
//   phoneno: Yup.string().optional(),
//   // .min(Number(12), 'Invalid Phone Number'),
//   mobileno: Yup.string().optional(),
//   // .min(Number(12), 'Invalid Mobile Number'),
// });
export const cvBankSchema = Yup.object().shape({
  keyword: Yup.string().trim().nullable().optional(),
  keywordType: Yup.string()
    .trim()
    .when('keyword', {
      is: (va: any) => va !== '',
      then(schema) {
        return schema.required('Keyword Type is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  mobile: Yup.string().trim().nullable().optional(),
  countryCode: Yup.string().trim().nullable().optional(),
  experienceMin: Yup.string().trim().nullable().optional(),
  experienceMax: Yup.string().trim().nullable().optional(),
  ctcMin: Yup.string().trim().nullable().optional(),
  ctcMax: Yup.string().trim().nullable().optional(),
  ctcCurrency: Yup.string().trim().nullable().optional(),
  currentLocation: Yup.string().trim().nullable().optional(),
  functionalArea: Yup.string().trim().nullable().optional(),
  designation: Yup.string().trim().nullable().optional(),
  noticePeriod: Yup.string().trim().nullable().optional(),
  ugQualificationType: Yup.string().trim().nullable().optional(),
  pgQualificationType: Yup.string().trim().nullable().optional(),
  ugQualification: Yup.string().trim().nullable().optional(),
  pgQualification: Yup.string().trim().nullable().optional(),
  ugSpecialization: Yup.string().trim().nullable().optional(),
  pgSpecialization: Yup.string().trim().nullable().optional(),
  ugCourseType: Yup.string().trim().nullable().optional(),
  pgCourseType: Yup.string().trim().nullable().optional(),
  ugYear: Yup.string().trim().nullable().optional(),
  pgYear: Yup.string().trim().nullable().optional(),
  gender: Yup.string().trim().nullable().optional(),
  maritalStatus: Yup.string().trim().nullable().optional(),
  candidateAgeMin: Yup.string().trim().nullable().optional(),
  candidateAgeMax: Yup.string().trim().nullable().optional(),
  createdFrom: Yup.string().trim().nullable().optional(),
  createdTo: Yup.string().trim().nullable().optional(),
  order: Yup.string().trim().nullable().optional(),
  sortby: Yup.string().trim().nullable().optional(),
});
export const copyResumeSchema = Yup.object().shape({
  clientId: Yup.string().trim().nullable().required('Client is required'),
  positionId: Yup.string().trim().nullable().required('Poistion is required'),
  oldResumeId: Yup.string().optional(),
});
export const CRMSchema = Yup.object({
  items: Yup.array().of(
    Yup.object().shape({
      choosecrm: Yup.string().trim().required('CRM is required'),
      rowId: Yup.string().optional(),
      title: Yup.string().optional(),
      firstname: Yup.string().optional(),
      lastname: Yup.string().optional(),
      designation: Yup.string().optional(),
      email: Yup.string().optional(),
      countryCodeText: Yup.string().optional(),
      phoneno: Yup.string().optional(),
      // .min(Number(12), 'Invalid Phone Number'),
      mobileno: Yup.string().optional(),
      // .min(Number(12), 'Invalid Mobile Number'),
    }),
  ),
});

export const SPOCSchema = Yup.object({
  editReason: Yup.string().trim().required('Reason for Editing is required'),
  items: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().trim().required('Title is required'),
      firstName: Yup.string().trim().required('First Name is required'),
      lastName: Yup.string().trim().required('Last Name is required'),
      designationId: Yup.string().trim().required('Designation is required'),
      email: Yup.string().trim().required('Email is required'),
      // countryCodeText: Yup.string().optional(),
      countryCode: Yup.string().optional(),
      // phoneNumber: Yup.string().trim().required('Phone No is required'),
      phoneNumber: Yup.string()
        .trim()
        .required('Phone No is required')

        .test('is-greater-check', 'Invalid Phone Number', function (value) {
          // const { countryCode }: any = this.parent;
          const countryCodeText1: any =
            value.length > 12 && typeof value === 'number' ? parsePhoneNumber(`+${value}`) : '';
          // return value.length > 6 ?  countryCodeText1.isValid() && (countryCodeText1.country === 'IN' || 'in') && value.length > 8 ? true:countryCodeText1.isValid() ? true : false : false;
          // return value.length > 6  && !_.isEmpty(countryCodeText1) && countryCodeText1.isValid() ? true : value.length >= 12 ? true :  false;
          return value.length > 12 && !_.isEmpty(countryCodeText1) && countryCodeText1.isValid()
            ? true
            : value.length >= 12
              ? true
              : false;
          // const countryCodeText:any = countryCodeText1 && countryCodeText1.country ? String(countryCodeText1.country).toUpperCase() : 'IN';

          // const formatval1: string = countryCode && value ? value.slice(countryCode.length) : value;
          // const formatval: string = formatval1 ? formatval1.replace(/[^\d+]/g, '') : formatval1;

          // return !countryCodeText ||
          //   (Number(formatval?.charAt(0)) !== 0 &&
          //     formatval?.match(/^(?!.*?(\w)\1{8}).*$/gm) !== null &&
          //     _.isUndefined(
          //       validatePhoneNumberLength(formatval.slice(1), countryCodeText),
          //     ))
          //   ? countryCodeText.toUpperCase() === 'IN'
          //     ? formatval.length > 9
          //       ? true
          //       : false
          //     : true
          //   : countryCodeText.toUpperCase() === 'US'
          //     ? formatval.length >= 10
          //       ? true
          //       : false
          //     : false;
        }),
      mobileno: Yup.string().trim().optional(),
      // .min(Number(12), 'Invalid Mobile Number'),
      // spocstatus: Yup.boolean().required('SPOC Status is required'),
      id: Yup.string().optional(),
      status: Yup.boolean().optional(),
      branchId: Yup.string().trim().required('SPOC’s Branch is required'),
      sameAsCorporateAddress: Yup.boolean().optional(),
      address1: Yup.string().trim().required('Address 1 is required'),
      address2: Yup.string().trim().optional(),
      country: Yup.string().trim().required('Country is required'),
      pincode: Yup.string()
        .trim()
        .required('Pincode is required')
        .min(Number(3), 'Invalid Pincode')
        .max(Number(6), 'Invalid Pincode'),
      city: Yup.string().trim().required('City is required'),
      state: Yup.string().trim().required('State is required'),
    }),
  ),
});

export const CVRejectSchema = Yup.object().shape({
  approvalStatus: Yup.string().required(),
  reason: Yup.string()
    .trim()
    .when('approvalStatus', {
      is: 'rejected',
      then(schema) {
        return schema.required('Reason is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  remarks: Yup.string().trim().optional(),
});
export const InterviewRejectSchema = Yup.object().shape({
  status: Yup.string().required(),
  reason: Yup.string()
    .trim()
    .when('status', {
      is: 'rejected',
      then(schema) {
        return schema.required('Reason is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  isApplicableForNextInterview: Yup.boolean().when('status', {
    is: 'approved',
    then(schema) {
      return schema.required('Next Interview Status is required');
    },
    otherwise(schema1) {
      return schema1.optional();
    },
  }),
  remarks: Yup.string().trim().optional(),
});

export const OfferStatusSchema = Yup.object().shape({
  status: Yup.string().required(),
  offeredDate: Yup.string().when('status', {
    is: 'OA',
    then(schema) {
      return schema.required('Offered date is required');
    },
    otherwise(schema1) {
      return schema1.optional();
    },
  }),
  offeredCtc: Yup.number()
    .transform((v) => (Number.isNaN(v) ? null : v))
    .max(10000000000, 'Offered CTC must be less than 100 lacs')
    .positive('Offered CTC must be a positive number')
    .typeError('Offered CTC is required')
    .when('status', {
      is: 'OA',
      then(schema) {
        return schema.required('Offered CTC is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  reason: Yup.string()
    .trim()
    .when('status', {
      is: 'OR',
      then(schema) {
        return schema.required('Reason is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  remarks: Yup.string().trim().optional(),
});

export const JoiningStatusSchema = Yup.object().shape({
  status: Yup.string().required(),
  joinedDate: Yup.string().when('status', {
    is: (va: any) => va === 'J' || va === 'JD',
    then(schema) {
      return schema.required('Date is required');
    },
    otherwise(schema1) {
      return schema1.optional();
    },
  }),
  reason: Yup.string()
    .trim()
    .when('status', {
      is: (va: any) => va === 'NJ' || va === 'JD',
      then(schema) {
        return schema.required('Reason is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  remarks: Yup.string().trim().optional(),
});

export const CvShortListSchema = Yup.object().shape({
  status: Yup.string().required(),
  reason: Yup.string()
    .trim()
    .when('status', {
      is: 'R',
      then(schema) {
        return schema.required('Reason is required');
      },
      otherwise(schema1) {
        return schema1.optional();
      },
    }),
  remarks: Yup.string().trim().optional(),
});
export const InterviewDetailsMultipleSchema = Yup.object().shape({
  client: Yup.string().optional(),
  cc: Yup.array().optional(),
  candidate: Yup.string().optional(),
  interviewDetails: Yup.array().of(
    Yup.object().shape({
      interviewType: Yup.string().optional(),
      rescheduleReason: Yup.string()
        .trim()
        .when('interviewType', {
          is: 'reSchedule',
          then(schema) {
            return schema.required('Re-schedule Reason is required');
          },
          otherwise(schema) {
            return schema.optional();
          },
        }),
      resumeId: Yup.string().optional(),
      interviewDuration: Yup.string().required('Interview Duration is required'),
      interviewLevel: Yup.string().required('Interview Level is required'),
      interviewMode: Yup.string().required('Interview Mode is required'),
      interviewDateTime: Yup.string().nullable().required('Interview Date & Time is required'),
      interviewVenue: Yup.string()
        .trim()
        .when('interviewMode', (value: any) => {
          if (value == 'faceToFace') {
            return Yup.string().nullable().required('Interview Venue is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      interviewVenueAddress: Yup.string()
        .nullable()
        .when('interviewMode', (value: any) => {
          if (value == 'faceToFace') {
            return Yup.string().nullable().required('Interview Venue Address is required');
          } else {
            return Yup.string().nullable().optional();
          }
        }),
      spocId: Yup.string().trim().optional(),
      contactPerson: Yup.string().trim().required('Contact person is required'),
      contactNo: Yup.string().trim().trim().required('Contact number is required'),
      additionalInfo: Yup.string().trim().optional(),
    }),
  ),
});
export const InterviewDetailsSchema = Yup.object().shape({
  interviewType: Yup.string().nullable().optional(),
  rescheduleReason: Yup.string()
    .trim()
    .when('interviewType', {
      is: 'schedule',
      then(schema) {
        return schema.optional();
      },
      otherwise(schema1) {
        return schema1.required('Re-schedule Reason is required');
      },
    }),
  client: Yup.string().optional(),
  cc: Yup.array().optional(),
  candidate: Yup.string().optional(),
  interviewDuration: Yup.string().nullable().required('Interview Duration is required'),
  interviewLevel: Yup.string().nullable().required('Interview Level is required'),
  interviewMode: Yup.string().nullable().required('Interview Mode is required'),
  interviewDateTime: Yup.string().nullable().required('Interview Date & Time is required'),
  interviewVenue: Yup.string()
    .trim()
    .when('interviewMode', (value: any) => {
      if (value == 'faceToFace') {
        return Yup.string().nullable().required('Interview Venue is required');
      } else {
        return Yup.string().nullable().optional();
      }
    }),
  interviewVenueAddress: Yup.string()
    .nullable()
    .when('interviewMode', (value: any) => {
      if (value == 'faceToFace') {
        return Yup.string().nullable().required('Interview Venue Address is required');
      } else {
        return Yup.string().nullable().optional();
      }
    }),
  spocId: Yup.string().nullable().optional(),
  contactPerson: Yup.string().trim().required('Contact person is required'),
  contactNo: Yup.string().trim().trim().required('Contact number is required'),
  additionalInfo: Yup.string().trim().optional(),
});
export const InterviewConfirmationClientSchema = Yup.object().shape({
  client: Yup.string().optional(),
  subject: Yup.string().trim().required('Subject is required'),
  message: Yup.string().trim().required('Message is required'),
});
export const InterviewConfirmationCandidateSchema = Yup.object().shape({
  candidate: Yup.string().optional(),
  subject: Yup.string().trim().required('Subject is required'),
  message: Yup.string().trim().required('Message is required'),
});
export const MailBoxSchema = Yup.object().shape({
  cc: Yup.array().optional(),
});
// export const SPOCSchema = Yup.object().shape({
//   title: Yup.string().trim().required('Title is required'),
//   firstName: Yup.string().trim().required('First Name is required'),
//   lastName: Yup.string().trim().required('Last Name is required'),
//   designationId: Yup.string().trim().required('Designation is required'),
//   email: Yup.string().trim().required('Email is required'),
//   // countryCodeText: Yup.string().optional(),
//   countryCode: Yup.string().optional(),
//   phoneNumber: Yup.string().trim().required('Phone No is required'),
//   mobileno: Yup.string().trim().optional(),
//   // .min(Number(12), 'Invalid Mobile Number'),
//   // spocstatus: Yup.boolean().required('SPOC Status is required'),
//   id: Yup.string().optional(),
//   status: Yup.boolean().optional(),
//   branchId: Yup.string().trim().required('SPOC’s Branch is required'),
//   sameAsCorporateAddress: Yup.boolean().optional(),
//   address1: Yup.string().trim().required('Address1 is required'),
//   address2: Yup.string().trim().optional(),
//   country: Yup.string().trim().required('Country is required'),
//   pincode: Yup.string()
//     .trim()
//     .required('Pincode is required')
//     .min(Number(3), 'Invalid Pincode')
//     .max(Number(6), 'Invalid Pincode'),
//   city: Yup.string().trim().required('City is required'),
//   state: Yup.string().trim().required('State is required'),
// });
// export const SPOCSchema = Yup.object().shape({
//   spoc: Yup.array().of(Yup.object().shape({
//     title: Yup.string().trim().required('Title is required'),
//     firstname: Yup.string().trim().required('First Name is required'),
//     lastname: Yup.string().trim().required('Last Name is required'),
//     designation: Yup.string().trim().required('Designation is required'),
//     email: Yup.string().trim().required('Email is required'),
//     countryCodeText: Yup.string().optional(),
//     phoneno: Yup.string()
//       .trim()
//       .optional()
//       .test('is-greater-check', 'Invalid Phone No', function (value) {
//         const { countryCodeText, countryCode }: any = this.parent;
//         const formatval1: any = countryCode && value ? value.slice(countryCode.length) : value;
//         const formatval: string = formatval1 ? formatval1.replace(/[^\d+]/g, '') : formatval1;

//         return !countryCodeText ||
//           (Number(formatval?.charAt(0)) !== 0 &&
//             formatval?.match(/^(?!.*?(\w)\1{8}).*$/gm) !== null &&
//             _.isUndefined(
//               validatePhoneNumberLength(formatval.slice(1), countryCodeText.toUpperCase()),
//             ))
//           ? countryCodeText.toUpperCase() === 'IN'
//             ? formatval.length > 9
//               ? true
//               : false
//             : true
//           : countryCodeText.toUpperCase() === 'US'
//             ? formatval.length >= 10
//               ? true
//               : false
//             : false;
//       }),
//     mobileno: Yup.string()
//       .trim()
//       .optional()
//       .test('is-greater-check', 'Invalid Mobile No', function (value) {
//         const { countryCodeText, countryCode }: any = this.parent;
//         const formatval1: any = countryCode && value ? value.slice(countryCode.length) : value;
//         const formatval: string = formatval1 ? formatval1.replace(/[^\d+]/g, '') : formatval1;

//         return !countryCodeText ||
//           (Number(formatval?.charAt(0)) !== 0 &&
//             formatval?.match(/^(?!.*?(\w)\1{8}).*$/gm) !== null &&
//             _.isUndefined(
//               validatePhoneNumberLength(formatval.slice(1), countryCodeText.toUpperCase()),
//             ))
//           ? countryCodeText.toUpperCase() === 'IN'
//             ? formatval.length > 9
//               ? true
//               : false
//             : true
//           : countryCodeText.toUpperCase() === 'US'
//             ? formatval.length >= 10
//               ? true
//               : false
//             : false;
//       }),
//     // .min(Number(12), 'Invalid Mobile Number'),
//     // spocstatus: Yup.boolean().required('SPOC Status is required'),
//     spocstatus: Yup.boolean().optional(),
//     spocbranch: Yup.string().trim().required('SPOC’s Branch is required'),
//     check: Yup.boolean().optional(),
//     address1: Yup.string().trim().optional(),
//     address2: Yup.string().trim().optional(),
//     country: Yup.string().optional(),
//     pincode: Yup.string().optional(),
//     city: Yup.string().optional(),
//     state: Yup.string().optional(),
//   }))
// });
export const productAdminSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().nullable().required('Last Name is required'),
  email: Yup.string().trim().required('Email is required').email('Email is invalid'),
  countryCodeText: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  PhoneNumberFormatted: Yup.string()
    .phone('GB', 'Please enter a valid phone number')
    .required('Phone Number is required'),
  // .min(Number(12), 'Invalid Phone Number'),
  role: Yup.string().required('Role is required'),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  country: Yup.string().trim().required('Country is required'),
  saturdayleave: Yup.string().trim().required('Saturday Leave is required'),
  isSunday: Yup.boolean().required('Sunday is required'),
  pincode: Yup.string()
    .trim()
    .required('Pincode is required')
    .min(Number(3), 'Invalid Pincode')
    .max(Number(6), 'Invalid Pincode'),
  branchId: Yup.string().required('Branch is required'),
  empCode: Yup.string().required('Employee ID is required'),
});

export const ApplyLeaveSchema = Yup.object().shape({
  leaveFrom: Yup.string().required('Leave From is required'),
  leaveTo: Yup.string().required('Leave To is required'),
  leaveType: Yup.string().required('Leave Type is required'),
  sessionFrom: Yup.string().required('Session From is required'),
  sessionTo: Yup.string().required('Session To is required'),
  reason: Yup.string().trim().required('Reason is required'),
});

export const LeaveRemarksSchema = Yup.object().shape({
  remarks: Yup.string().required('Remarks is required'),
});

export const BillingApprovalSchema = Yup.object().shape({
  remarks: Yup.string().trim().required('Remarks is required'),
});

export const ImportLeaveSchema = Yup.object().shape({
  leavePolicyId: Yup.string().required('Leave Type is required'),
  year: Yup.string().required('Period is required'),
});
