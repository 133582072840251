/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Typography } from '@mui/material';
import AddIcon from 'assets/iconcomponents/addicon';
import SPOCFORM from './form';
import { useState } from 'react';

const SPOCDETAIL = ({
  submt,
  handleOpenctg,
  handleClosectg,
}: {
  submt: any;
  handleOpenctg: any;
  handleClosectg: any;
}) => {
  const itemsdefault = {
    title: '',
    firstName: '',
    lastName: '',
    designationId: '',
    email: '',
    phoneNumber: undefined,
    mobileno: undefined,
    status: !false,
    branchId: '',
    address1: undefined,
    address2: undefined,
    country: '',
    pincode: '',
    city: '',
    sameAsCorporateAddress: false,
    state: '',
    countryCode: '',
    id: '',
    // countryCodeText:'',
  } as const;
  const [items, setitems] = useState([itemsdefault]);
  const [submit, setsubmit] = useState(false);
  function onSubmitForm(data: any) {
    submt(data);
    // clicks(data);
    // setsubmit(true);
  }

  function inadd() {
    setitems((p1: any) => [...p1, itemsdefault]);
  }
  function indelet(idx: number) {
    // const items1 = items.map((io:any,idy:number) => idx === idy ? {} : io );
    const items1 = items.filter((io: any, idy: number) => idx !== idy);
    setitems((p1: any) => [...items1]);
  }

  return (
    <Grid item md={8} sm={8} xs={8}>
      <Typography component={'div'} className='form-box'>
        {/* <form id={'user-add'} onSubmit={onSubmitForm}> */}
        <SPOCFORM
          submit={submit}
          onSubmited={onSubmitForm}
          items={items}
          setitems={setitems}
          itemsdefault={itemsdefault}
          handleOpenctg={handleOpenctg}
          handleClosectg={handleClosectg}
          inadd={inadd}
          indelete={indelet}
        />
        {/* <SPOCFORM submit={submit}/> */}
        {/* <SPOCFORM submit={submit}/> */}
        {/* </form> */}
      </Typography>
    </Grid>
  );
};

export default SPOCDETAIL;
