/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Checkbox, InputAdornment, Typography, Box, Switch } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller } from 'react-hook-form';
import { ClientDetailSchema, userFormSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import { ClientDetailType } from 'types';
import clientcorporatereducer, {
  storeclient,
  storeimage,
  storelogoid,
} from 'store/reducers/clientcorporatereducer';
import { toast } from 'react-toastify';
import ImageWithAuth from 'components/ProfileImage';
import { Loader } from 'components/loader';
import CustomSelect from 'components/CustomSelect/customSelect';
import { getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { Country, State, City } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const CLIENTDETAILS = ({ click }: { click: any }) => {
  const defaultText =
    '<p><strong>New Content</strong></p><ul><li><br></li></ul><p><strong>Template</strong>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</p><ol><li>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</li></ol>';
  const [uploadImage, setUploadImage] = useState<any>({});
  const [localImage, setLocalImage] = useState<any>('');
  const [nonlocalImage, setnonlocalImage] = useState<any>('');
  const imgref: any = useRef();
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('mode');
  const { mode } = useParams();
  const [Ld, setLd] = useState(false);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const clientreducer: any = useAppSelector((store: any) => store.clientreducer);
  const Corporatestore: any = useAppSelector((store: any) => store.corporatereducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [industryDatas, setIndustryDatas] = useState<Array<{ label: string; value: string }>>([]);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    trigger,
    getValues,
    // setError,
    watch,
    formState: { errors },
  } = useForm<ClientDetailType>({
    criteriaMode: 'all',
    defaultValues: {
      corporateName: '',
      address1: undefined,
      address2: undefined,
      country: '',
      pincode: '',
      city: '',
      comments: '',
      state: '',
      logoId: '',
      companylogo: false,
      status: true,
      industryId: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ClientDetailSchema),
  });
  // useLayoutEffect(() => {
  //   paramValue !== 'edit' ? resetForm() :'';
  // },[]);

  // useEffect(() => {
  //   if (!_.isEmpty(Corporatestore.client) && paramValue === 'edit') {
  //     setValue(
  //       'logoId',
  //       Corporatestore.client && Corporatestore.client.logoId ? Corporatestore.client.logoId : '',
  //     );
  //     Corporatestore.client && Corporatestore.client.logoId
  //       ? setnonlocalImage(Corporatestore.client.logoId)
  //       : '';
  //     setValue(
  //       'corporateName',
  //       Corporatestore.client && Corporatestore.client.corporateName
  //         ? Corporatestore.client.corporateName
  //         : '',
  //     );
  //     setValue(
  //       'industryId',
  //       Corporatestore.client && Corporatestore.client.industryId
  //         ? Corporatestore.client.industryId
  //         : '',
  //     );
  //     setValue(
  //       'comments',
  //       Corporatestore.client && Corporatestore.client.comments
  //         ? Corporatestore.client.comments
  //         : '',
  //     );
  //     setValue(
  //       'address1',
  //       Corporatestore.client && Corporatestore.client.address1
  //         ? Corporatestore.client.address1
  //         : undefined,
  //     );
  //     setValue(
  //       'address2',
  //       Corporatestore.client && Corporatestore.client.address2
  //         ? Corporatestore.client.address2
  //         : undefined,
  //     );
  //     setValue(
  //       'city',
  //       Corporatestore.client && Corporatestore.client.city ? Corporatestore.client.city : '',
  //     );
  //     setValue(
  //       'country',
  //       Corporatestore.client && Corporatestore.client.country ? Corporatestore.client.country : '',
  //     );
  //     setValue(
  //       'pincode',
  //       Corporatestore.client && Corporatestore.client.pincode ? Corporatestore.client.pincode : '',
  //     );
  //     setValue(
  //       'state',
  //       Corporatestore.client && Corporatestore.client.state ? Corporatestore.client.state : '',
  //     );
  //     setValue(
  //       'status',
  //       Corporatestore.client && Corporatestore.client.status
  //         ? Corporatestore.client.status
  //         : false,
  //     );
  //   }
  //   if (!_.isEmpty(Corporatestore.client) && paramValue !== 'edit') {
  //     setValue(
  //       'corporateName',
  //       Corporatestore.client && Corporatestore.client.corporateName
  //         ? Corporatestore.client.corporateName
  //         : '',
  //     );
  //     setValue(
  //       'comments',
  //       Corporatestore.client && Corporatestore.client.comments
  //         ? Corporatestore.client.comments
  //         : '',
  //     );
  //     setValue(
  //       'address1',
  //       Corporatestore.client && Corporatestore.client.address1
  //         ? Corporatestore.client.address1
  //         : undefined,
  //     );
  //     setValue(
  //       'address2',
  //       Corporatestore.client && Corporatestore.client.address2
  //         ? Corporatestore.client.address2
  //         : undefined,
  //     );
  //     setValue(
  //       'city',
  //       Corporatestore.client && Corporatestore.client.city ? Corporatestore.client.city : '',
  //     );
  //     setValue(
  //       'country',
  //       Corporatestore.client && Corporatestore.client.country ? Corporatestore.client.country : '',
  //     );
  //     setValue(
  //       'pincode',
  //       Corporatestore.client && Corporatestore.client.pincode ? Corporatestore.client.pincode : '',
  //     );
  //     setValue(
  //       'state',
  //       Corporatestore.client && Corporatestore.client.state ? Corporatestore.client.state : '',
  //     );
  //     setValue(
  //       'status',
  //       Corporatestore.client && Corporatestore.client.status
  //         ? Corporatestore.client.status
  //         : false,
  //     );
  //     // Corporatestore.client && Corporatestore.client.clientLogo ? setUploadImage(Corporatestore.client.clientLogo):'';
  //     Corporatestore.clientimg.file2 ? setLocalImage(Corporatestore.clientimg.file2) : '';
  //     const k = localStorage.getItem('refreshstate');
  //     const lch = k && JSON.parse(k) && JSON.parse(k).refresh;
  //     !lch &&
  //     Corporatestore.client &&
  //     !_.isEmpty(Corporatestore.client) &&
  //     Corporatestore.client.clientLogo
  //       ? setLocalImage(URL.createObjectURL(Corporatestore.client.clientLogo))
  //       : '';
  //   }
  // }, [Corporatestore.client]);

  useEffect(() => {
    if (!_.isEmpty(Corporatestore.client) && paramValue === 'edit') {
      setValue(
        'logoId',
        Corporatestore.client && Corporatestore.client.logoId ? Corporatestore.client.logoId : '',
      );
      Corporatestore.client && Corporatestore.client.logoId
        ? setnonlocalImage(Corporatestore.client.logoId)
        : '';

      Corporatestore.logoId ? setnonlocalImage(Corporatestore.logoId) : '';
      Corporatestore && Corporatestore.logoId && Corporatestore.logoId.id
        ? setnonlocalImage(Corporatestore.logoId.id)
        : '';

      setValue(
        'corporateName',
        Corporatestore.client && Corporatestore.client.corporateName
          ? Corporatestore.client.corporateName
          : '',
      );

      setValue(
        'industryId',
        Corporatestore.client && Corporatestore.client.industryId
          ? Corporatestore.client.industryId
          : '',
      );

      setValue(
        'comments',
        Corporatestore.client && Corporatestore.client.comments
          ? Corporatestore.client.comments
          : '',
      );
      setValue(
        'address1',
        Corporatestore.client && Corporatestore.client.address1
          ? Corporatestore.client.address1
          : undefined,
      );
      setValue(
        'address2',
        Corporatestore.client && Corporatestore.client.address2
          ? Corporatestore.client.address2
          : undefined,
      );
      setValue(
        'city',
        Corporatestore.client && Corporatestore.client.city ? Corporatestore.client.city : '',
      );
      setValue(
        'country',
        Corporatestore.client && Corporatestore.client.country ? Corporatestore.client.country : '',
      );
      setValue(
        'pincode',
        Corporatestore.client && Corporatestore.client.pincode ? Corporatestore.client.pincode : '',
      );
      setValue(
        'state',
        Corporatestore.client && Corporatestore.client.state ? Corporatestore.client.state : '',
      );
      setValue(
        'status',
        Corporatestore.client && Corporatestore.client.status
          ? Corporatestore.client.status
          : false,
      );
    }
    if (!_.isEmpty(Corporatestore.client) && paramValue !== 'edit') {
      setValue(
        'corporateName',
        Corporatestore.client && Corporatestore.client.corporateName
          ? Corporatestore.client.corporateName
          : '',
      );

      setValue(
        'industryId',
        Corporatestore.client && Corporatestore.client.industryId
          ? Corporatestore.client.industryId
          : '',
      );

      setValue(
        'comments',
        Corporatestore.client && Corporatestore.client.comments
          ? Corporatestore.client.comments
          : '',
      );
      setValue(
        'address1',
        Corporatestore.client && Corporatestore.client.address1
          ? Corporatestore.client.address1
          : undefined,
      );
      setValue(
        'address2',
        Corporatestore.client && Corporatestore.client.address2
          ? Corporatestore.client.address2
          : undefined,
      );
      setValue(
        'city',
        Corporatestore.client && Corporatestore.client.city ? Corporatestore.client.city : '',
      );
      setValue(
        'country',
        Corporatestore.client && Corporatestore.client.country ? Corporatestore.client.country : '',
      );
      setValue(
        'pincode',
        Corporatestore.client && Corporatestore.client.pincode ? Corporatestore.client.pincode : '',
      );
      setValue(
        'state',
        Corporatestore.client && Corporatestore.client.state ? Corporatestore.client.state : '',
      );
      setValue(
        'status',
        Corporatestore.client && Corporatestore.client.status
          ? Corporatestore.client.status
          : false,
      );
      // Corporatestore.client && Corporatestore.client.clientLogo ? setUploadImage(Corporatestore.client.clientLogo):'';
      Corporatestore.clientimg.file2 ? setLocalImage(Corporatestore.clientimg.file2) : '';
      const k = localStorage.getItem('refreshstate');
      const lch = k && JSON.parse(k) && JSON.parse(k).refresh;
      !lch &&
      Corporatestore.client &&
      !_.isEmpty(Corporatestore.client) &&
      Corporatestore.client.clientLogo
        ? setLocalImage(URL.createObjectURL(Corporatestore.client.clientLogo))
        : '';
    }
  }, [Corporatestore.client]);

  useEffect(() => {
    String(getValues('logoId'))
      ? setnonlocalImage(String(getValues('logoId')))
      : Corporatestore && Corporatestore.logoId && Corporatestore.logoId.id
        ? setnonlocalImage(String(Corporatestore.logoId.id))
        : '';
  }, [getValues('logoId')]);

  function onSubmitForm(datas: any) {
    setLd(true);
    if (!Corporatestore.clientimg.exist && !clientreducer.logoId && paramValue !== 'edit') {
      setLd(false);
      toast.error(String('Company Logo is required'), {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
      return;
    }

    const data: any = {
      corporateName: datas && datas.corporateName ? datas.corporateName : '',
      industryId: datas.industryId,
      address1: datas && datas.address1 ? datas.address1 : '',
      // address2: datas && datas.address2 ? datas.address2 : '',
      country: datas && datas.country ? datas.country : '',
      pincode: datas && datas.pincode ? datas.pincode : '',
      city: datas && datas.city ? datas.city : '',
      state: datas && datas.state ? datas.state : '',
      comments: datas && datas.comments ? datas.comments : '',

      status: datas && datas.status ? datas.status : false,
      // clientLogo: {localImage:localImage,name:!_.isEmpty(uploadImage) && uploadImage[0].name?uploadImage[0].name:'',type:!_.isEmpty(uploadImage) && !_.isEmpty(uploadImage[0]) && uploadImage[0].type ?  uploadImage[0].type :''},
    };

    datas && datas.address2
      ? dispatch(storeclient({ ...data, address2: datas.address2 }))
      : dispatch(storeclient({ ...data }));
    datas && datas.address2
      ? localStorage.setItem('clientdetail', JSON.stringify({ ...data, address2: datas.address2 }))
      : localStorage.setItem('clientdetail', JSON.stringify({ ...data }));
    click(data);
    setLd(false);
  }

  const industryData = () => {
    getAllListData(
      `${SETTING_ENDPOINT.Industry}?organizationId=${orgdy}&status=true&sortby=name&order=asc`,
      true,
    )
      .then((res) => {
        const options: Array<{ label: string; value: string }> = [];
        if (res.data.length) {
          res.data.forEach((e: any) => {
            options.push({ label: e.name, value: e.id });
          });
        }
        setIndustryDatas(options);
        setLd(false);
      })
      .catch(() => {
        setLd(false);
      });
  };

  useEffect(() => {
    industryData();
  }, []);

  useEffect(() => {
    if (watch('country')) {
      const options: any = [];
      State.getStatesOfCountry(watch('country')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStates(options);
    }
  }, [watch('country')]);

  useEffect(() => {
    if (watch('state')) {
      const options: any = [];
      City.getCitiesOfState(watch('country'), watch('state')).map((city: any) => {
        options.push({ label: city.name, value: city.name });
      });
      setCities(options);
    }
  }, [watch('country'), watch('state')]);

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  return (
    <Grid item md={8} sm={8} xs={8}>
      {Ld && <Loader />}
      <Typography component={'div'} className='form-box'>
        <Typography component={'div'} className='client-form-photo-area'>
          <Typography component={'p'} className='side-client-text'>
            Client Information
          </Typography>
          <Box className='main-box main-box-corporate-info'>
            <Typography component={'p'} className='client-img-container'>
              <div className='img-placeholder'>
                {localImage ? (
                  <img className='default-pic' src={localImage} />
                ) : _.isEmpty(localImage) && !_.isEmpty(nonlocalImage) ? (
                  <ImageWithAuth className='default-pic' id={nonlocalImage} />
                ) : (
                  <span className='no-logo'>Logo</span>
                )}
              </div>
              <div className='img-info-con'>
                <div className='img-info-title'>
                  {' '}
                  <header>
                    Company Logo <span style={{ color: 'red' }}>*</span>
                  </header>
                  <span className='sub-info'>upload (.jpg, .jpeg, .png) file</span>
                </div>
                <div
                  className='img-click-btn'
                  onClick={() => imgref && imgref.current && imgref.current.click()}>
                  Upload Picture
                </div>
              </div>
              <input
                hidden
                type='file'
                accept='image/*'
                ref={imgref}
                onChange={(e: any) => {
                  setUploadImage(e?.target?.files);
                  dispatch(
                    storeimage({
                      file: e?.target?.files[0],
                      exist: true,
                      file2: URL.createObjectURL(e?.target?.files[0]),
                    }),
                  );
                  setLocalImage(URL.createObjectURL(e?.target?.files[0]));
                }}
              />
            </Typography>
            {paramValue === 'edit' ? (
              <Typography className='status-text text-center'>
                Client Status
                <Controller
                  control={control}
                  defaultValue={true}
                  name='status'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Switch
                        value={value}
                        name={name}
                        // defaultChecked={true}
                        checked={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-switch'
                      />
                    );
                  }}
                />
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Typography>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container columnSpacing={4.25}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Name <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='corporateName'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Enter Name'
                        error={errors.corporateName?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Industry <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={''}
                  name='industryId'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={
                          industryDatas.length ? industryDatas : [{ label: '', value: null }]
                        }
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeHolder='Select'
                        error={errors?.industryId?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Address 1 <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='address1'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Enter Address'
                        error={errors.address1?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Address 2</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='address2'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Enter Address'
                        error={errors.address2?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Country <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='country'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={country}
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Country'
                        error={errors.country?.message}
                        onChange={(e) => {
                          onChange(e);
                          setValue('state', '');
                          setValue('city', '');
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  State <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='state'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={states}
                        value={value ? value : null}
                        name={name}
                        className='custom-input'
                        placeHolder='State'
                        error={errors.state?.message}
                        onChange={(e) => {
                          onChange(e);
                          setValue('city', '');
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  City <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='city'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={cities}
                        value={value ? value : null}
                        name={name}
                        className='custom-input'
                        placeHolder='City'
                        error={errors.city?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Pincode <span>*</span>
                </Typography>

                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='pincode'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        className='custom-input'
                        onChange={onChange}
                        value={value}
                        name={name}
                        placeHolder='Enter Pincode'
                        type={'number'}
                        error={errors.pincode?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>

            <Grid item md={6} sm={12} xs={12} sx={{ display: 'none' }}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Country <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='country'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Country'
                        error={errors.country?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Comments</Typography>
                {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      isTextArea={true}
                      className='custom-input'
                      placeHolder='Write your content here'
                      error={errors.comments?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name='comments'
                  control={control}
                />
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Typography>
    </Grid>
  );
};

export default CLIENTDETAILS;
