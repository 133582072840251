import { Box, Breadcrumbs, Button, Divider, Grid, Link, Modal, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { getAllListData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import ImageWithAuth from 'components/ProfileImage';
import Closepng from 'assets/png/close.svg';
import LeftIco from 'assets/png/LeftArrowIcon.svg';
import PrintIco from 'assets/png/printIcon.svg';
import * as R from 'ramda';
import Spoclocation from 'assets/png/spocLocation.svg';
import { useAppSelector } from 'store/hooks';
import { Country, State } from 'country-state-city';

const ClientViewDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    menuList,
    userInfo: { id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [clientData, setClientData] = useState<{
    logoData: { logoId: string; corporateName: string; location: string; status: boolean };
    corporateDetails: Array<{ key: string; value: string }>;
    crmDetails: Array<any>;
    spocDetails: Array<any>;
    spocAllData: Array<any>;
    data: any;
  }>({
    logoData: { logoId: '', corporateName: '', location: '', status: true },
    corporateDetails: [{ key: '', value: '' }],
    crmDetails: [],
    spocDetails: [],
    spocAllData: [],
    data: {},
  });
  const [clientModule, setClientModule] = useState([]);
  const [openloca, setOpenloca] = useState(false);
  const [localspocaddress, setlocalspocaddress] = useState<any>({});
  const [opnspocbtndsc, setopnspocbtndsc] = useState(false);
  const [print, setPrint] = useState(false);

  const handleOpenloca = () => setOpenloca(true);
  const handleCloseloca = () => {
    setopnspocbtndsc(!true);
    setOpenloca(false);
  };
  const handleOpenspocdsc = (data: any) => {
    setlocalspocaddress(data);
    setopnspocbtndsc(true);
  };
  const handleClosespocdsc = () => {
    setlocalspocaddress({});
    setopnspocbtndsc(false);
  };

  const gotoNavigate = (link: To, state?: any) => {
    navigate(link, state);
  };

  const getClientData = () => {
    getAllListData(`${ClientsENDPOINT.Client}?id=${location.state.id}`)
      .then((res) => {
        const data = res.data[0];
        const clientCountry = Country.getAllCountries().filter(
          (country) => country.isoCode === data?.country,
        );
        const clientState = State.getStatesOfCountry(data?.country).filter(
          (state: any) => state.isoCode === data?.state,
        );
        const corporateDetails = [
          {
            key: 'Address',
            value: data.address1,
          },
          {
            key: 'Country',
            value: clientCountry[0]?.name,
          },
          {
            key: 'State',
            value: clientState[0]?.name,
          },
          {
            key: 'City',
            value: data.city,
          },
          {
            key: 'Pin Code',
            value: data.pincode,
          },
          {
            key: 'Comments',
            value: data.comments,
          },
        ];

        const crmDetails: any[] = [];
        data.clientsCrm.forEach((crmData: any) => {
          crmDetails.push([
            // {
            //   key: 'Title',
            //   value: crmData.user.title,
            // },
            {
              key: 'First Name',
              value: crmData.user.firstName,
            },
            {
              key: 'Last Name',
              value: crmData.user.lastName,
            },
            {
              key: 'Designation',
              value: crmData.user.designation,
            },
            {
              key: 'Email',
              value: crmData.user.login.email,
            },
            {
              key: 'Phone No',
              value: `${crmData.user.countryCode} ${crmData.user.phoneNumber}`,
            },
          ]);
        });

        const spocDetails: any[] = [];
        data.clientsSpoc.forEach((spocData: any) => {
          const spocCountry = Country.getAllCountries().filter(
            (country) => country.isoCode === spocData?.country,
          );
          const spocState = State.getStatesOfCountry(spocData?.country).filter(
            (state: any) => state.isoCode === spocData?.state,
          );
          return spocDetails.push([
            {
              key: 'Title',
              value: spocData.title,
            },
            {
              key: 'First Name',
              value: spocData.firstName,
            },
            {
              key: 'Last Name',
              value: spocData.lastName,
            },
            {
              key: 'Designation',
              value: spocData.designation.name,
            },
            {
              key: 'Email',
              value: spocData.email,
            },
            {
              key: 'Phone No',
              value: `${spocData.countryCode} ${spocData.phoneNumber}`,
            },
            {
              key: 'Spoc Status',
              value: spocData.status ? 'Active' : 'Inactive',
            },
            {
              key: "Spoc's Branch",
              value: spocData.branch.name,
            },
            {
              key: 'Address 1',
              value: spocData.address1,
            },
            {
              key: 'Address 2',
              value: spocData.address2,
            },
            {
              key: 'Country',
              value: spocCountry[0]?.name,
            },
            {
              key: 'State',
              value: spocState[0]?.name,
            },
            {
              key: 'City',
              value: spocData.city,
            },

            {
              key: 'Pincode',
              value: spocData.pincode,
            },
          ]);
        });

        setClientData({
          logoData: {
            logoId: data.logoId,
            corporateName: data.corporateName,
            location: `${data.city}, ${data.state}, ${data.country}`,
            status: data.status,
          },
          corporateDetails,
          crmDetails,
          spocDetails,
          spocAllData: data.clientsSpoc,
          data,
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (print) {
      // // printref && printref.current && printref.current.innerHtml;
      window.print();
      setPrint(false);
    }
  }, [print]);

  useEffect(() => {
    if (!_.isEmpty(location.state.id)) {
      getClientData();
    }
  }, [location.state]);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Position');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Clients
            </Typography>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              //   onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEWDETAILS)}
            >
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Client
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Client</Typography>
          {clientData?.data?.clientsCrm?.find((e: any) => e.userId === userId) &&
            R.findIndex(R.propEq('Add Position', 'screenName'))(
              clientModule ? clientModule : [],
            ) !== -1 && (
              <Typography
                component={'div'}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  component={'div'}
                  sx={{ marginRight: '24px', cursor: 'pointer' }}
                  onClick={() =>
                    gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSADD, {
                      state: { clientId: location.state.id },
                    })
                  }>
                  <Button className='resume-save-btn'>Add Position</Button>
                </Typography>
              </Typography>
            )}
        </Typography>

        <Typography
          component={'div'}
          className='main-content view-position'
          style={{ padding: '20px' }}>
          <Typography component={'div'} className='white-box view-position-white-box'>
            <Grid container md={12} sm={12} xs={12}>
              <Grid md={9} sm={9} xs={9}>
                <Typography sx={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                  <Typography component={'div'} sx={{ width: '80px', height: '80px' }}>
                    {!_.isEmpty(clientData?.logoData) && clientData?.logoData?.logoId ? (
                      <ImageWithAuth
                        styled={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '100%',
                          objectFit: 'cover',
                          transform: 'translateY(-4px)',
                          border: '1px solid #D9D9D9',
                        }}
                        id={clientData.logoData.logoId}
                      />
                    ) : (
                      <></>
                    )}
                  </Typography>
                  <Typography>
                    <Typography
                      sx={{
                        color: '#344051 !important',
                        fontFamily: 'Poppins-SemiBold !important',
                        fontSize: '18px',
                        textTransform: 'capitalize',
                      }}>
                      {clientData.logoData.corporateName}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#637083 !important',
                        fontFamily: 'Poppins-Regular !important',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '16px',
                        textTransform: 'capitalize',
                      }}>
                      {clientData.logoData.location}
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>

              <Grid md={3} sm={3} xs={3} sx={{ marginTop: '10px' }}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontFamily: 'Poppins-SemiBold !important',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    color: '#344051',
                  }}>
                  Client Status
                </Typography>
                <Typography
                  className={
                    clientData && clientData.logoData.status
                      ? 'client-active-badge'
                      : 'client-inactive-badge'
                  }>
                  {clientData && clientData.logoData.status ? 'Active' : 'Inactive'}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ marginBottom: '30px', marginTop: '34px' }} />

            <Typography
              sx={{ fontWeight: 600, fontSize: '16px', color: '#344051', marginBottom: '19px' }}>
              Corporate Details
            </Typography>
            <Grid container md={12} sm={12} xs={12}>
              {!_.isEmpty(clientData.corporateDetails) &&
                clientData.corporateDetails.map((e, i) => (
                  <Grid md={3} sm={3} xs={3} key={i}>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        color: '#191632',
                      }}>
                      {e.key}
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: '24px',
                        fontSize: '14px !important',
                        fontWeight: 400,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        color: '#637083',
                        textTransform: 'capitalize',
                      }}>
                      {e.value ? e.value : '--'}
                    </Typography>
                  </Grid>
                ))}
            </Grid>

            <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />

            <Typography
              sx={{ fontWeight: 600, fontSize: '16px', color: '#344051', marginBottom: '19px' }}>
              CRM Details
            </Typography>
            {!_.isEmpty(clientData.crmDetails) &&
              clientData.crmDetails.map((crmData, index) => (
                <>
                  <Grid container md={12} sm={12} xs={12} key={index}>
                    {!_.isEmpty(clientData.corporateDetails) &&
                      crmData.map((e: any, i: any) => (
                        <Grid md={3} sm={3} xs={3} key={i}>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              color: '#191632',
                            }}>
                            {e.key}
                          </Typography>
                          <Typography
                            sx={{
                              marginBottom: '24px',
                              fontSize: '14px !important',
                              fontWeight: 400,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              color: '#637083',
                              textTransform: 'capitalize',
                            }}>
                            {e.value ? e.value : '--'}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                  <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                </>
              ))}

            <Typography
              sx={{ fontWeight: 600, fontSize: '16px', color: '#344051', marginBottom: '10px' }}>
              Spoc Details
            </Typography>
            <div style={{ display: 'flex', marginBottom: '10px' }} onClick={handleOpenloca}>
              <div>
                <img src={Spoclocation} alt='spoc location image' />
              </div>
              <div
                style={{
                  color: '#5135ff',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  lineHeight: '16px',
                  transform: 'translateY(1.425px)',
                  cursor: 'pointer',
                }}>
                View Address Book
              </div>
            </div>
            {!_.isEmpty(clientData.spocDetails) &&
              clientData.spocDetails.map((spocData, index) => (
                <>
                  <Grid container md={12} sm={12} xs={12} key={index}>
                    {!_.isEmpty(clientData.corporateDetails) &&
                      spocData.map((e: any, i: any) => (
                        <Grid md={3} sm={3} xs={3} key={i}>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 600,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              color: '#191632',
                            }}>
                            {e.key}
                          </Typography>
                          <Typography
                            sx={{
                              marginBottom: '24px',
                              fontSize: '14px !important',
                              fontWeight: 400,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              color: '#637083',
                              textTransform: 'capitalize',
                            }}>
                            {e.value ? e.value : '--'}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                  <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                </>
              ))}

            <Modal open={openloca} onClose={handleCloseloca}>
              <Box
                className='spoc-list-modal'
                style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}>
                {!opnspocbtndsc ? (
                  <>
                    <img
                      src={Closepng}
                      alt='close'
                      className='closepng'
                      onClick={handleCloseloca}
                    />
                    <header>SPOC ADDRESS</header>
                    <div className='divider'></div>
                    <div className='button-con'>
                      {clientData.spocAllData.map((spoc: any, i: number) => {
                        return (
                          <>
                            {spoc && (
                              <button
                                onClick={() => {
                                  handleOpenspocdsc(spoc);
                                }}
                                key={i}>{`SPOC${i + 1} Address`}</button>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  !_.isEmpty(localspocaddress) && (
                    <>
                      <nav className={print ? 'displayNone' : 'spoc-client-info-nav'}>
                        <div className='left-ico'>
                          <img src={LeftIco} alt='lefticon' onClick={handleClosespocdsc} />
                        </div>
                        <div className='right-ico'>
                          <img
                            src={PrintIco}
                            alt='print'
                            onClick={() => {
                              setPrint(true);
                            }}
                          />
                          <img src={Closepng} alt='close' onClick={handleCloseloca} />
                        </div>
                      </nav>
                      {/* <div ref={printref}> */}
                      <div>
                        <main className='spoc-client-info-main'>
                          <header>
                            {localspocaddress.firstName
                              ? `${localspocaddress.firstName} ${
                                  localspocaddress.lastName ? localspocaddress.lastName : ''
                                }`
                              : '-'}
                          </header>
                          <div className='description'>
                            {`${
                              localspocaddress.designation ? localspocaddress.designation.name : '-'
                            } - ${
                              clientData && clientData.logoData.corporateName
                                ? clientData.logoData.corporateName.toUpperCase()
                                : ''
                            }.`}
                            {localspocaddress.address1 ? (
                              <div className='address-spocs'>{localspocaddress.address1}</div>
                            ) : (
                              <div className='address-spocs'>-</div>
                            )}
                            {localspocaddress.address2 ? (
                              <div className='address-spocs'>{localspocaddress.address2}</div>
                            ) : (
                              <div className='address-spocs'>-</div>
                            )}
                          </div>
                        </main>
                        <div className='spoc-client-info-divider'></div>
                        <div className='spoc-client-info-phnumber-con'>
                          <div className='landline'>
                            <span className='headng'>Phone No:</span>{' '}
                            {localspocaddress.phoneNumber
                              ? `${localspocaddress.countryCode} ${localspocaddress.phoneNumber}`
                              : ''}
                          </div>
                          <div className='mobile'>
                            {/* <span className='headng'>Mobile No:</span> +91-8101406716 */}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </Box>
            </Modal>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ClientViewDetails;
